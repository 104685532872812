import hhstore from './HHStore'

var md5 = require('md5')

// 判断手机号是不是正确
export const isPhoneNumber = (phoneNumber) => {
  const reg = /^1[3-9]\d{9}$/;
  return reg.test(phoneNumber);
}

// 让手机号中间四位变成 * 
export const hideMobileNumber = (mobileNumber) => {
  const reg = /(\d{3})\d{4}(\d{4})/;
  return mobileNumber.replace(reg, '$1****$2');
}

// 判断当前是ios还是android
export const getPlatform = () => {
  return new Promise((resolve, reject) => {
    if (/iphone|ipad|ipod/i.test(navigator.userAgent)) {
      resolve('IOS');
    } else if (/android/i.test(navigator.userAgent)) {
      resolve('Android');
    } else {
      resolve('H5');
    }
  });
}


// 随机字符串函数
export const randomString = (length) => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// 返回设备标识
export const udid = () => {
  let udid = hhstore.get('myudid')
  if (udid)
    return udid

  udid = randomString(32)
  hhstore.set('myudid', udid)
  return udid
}

// 签名函数
export const sign = (params, appid, key) => {
  // 添加 appid、timestamp 和 oncestr 参数
  params.appid = appid;
  params.timestamp = Math.floor(Date.now() / 1000);
  params.oncestr = randomString(20);

  // 删除非基本类型参数
  let data = Object.entries(params)
    .filter(([key, value]) => typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')
    .reduce((obj, [key, value]) => {
      obj[key] = value.toString();
      return obj;
    }, {});

  // 对键名进行排序
  const sortedKeys = Object.keys(data).sort();

  // 构建待签名字符串
  const query = sortedKeys.map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
  const queryString = `${query}&key=${key}`;

  // 计算签名
  const sign = md5.hex(queryString);

  // 在 post 参数中增加签名字段
  params.sign = sign;

  return params;
}
