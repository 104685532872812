<template>
  <div class="qyw-container">
    <img src="//img.zhsyh.cn/donglian/kefu3.png" class="qyw-kefu" @click="handlekefu" />
    <img src="//img.zhsyh.cn/donglian/text-top-left.png" class="qyw-top-left" />
    <img src="//img.zhsyh.cn/donglian/top.png" class="qyw-top" />
    <div v-if="jifen" class="qyw-jifen">
      <div class="qyw-jifen-info">
        <span class="qyw-jifen-info-1">{{ phone }}</span>
        <span class="qyw-jifen-info-2" @click="switchclicked">切换</span>
        <span class="qyw-jifen-info-3">移动积分：</span>
        <span class="qyw-jifen-info-4">{{ jifen }}</span>
      </div>
      <div class="qyw-jifen-bg">
        <div class="qyw-jifen-title">本次可兑换</div>
        <div class="qyw-jifen-price">
          {{ dhme }}<span class="qyw-jifen-price-yuan">元</span>
        </div>
        <div class="qyw-jifen-score">消耗移动积分：{{ dhjf }}，蜻蜓积分：{{ dhqt }}</div>

      </div>
      <sms @resend="handleResend" @tapok="handletapok"></sms>

      <div class="qyw-jifen-history">
        <div class="qyw-jifen-history-1" @click="handHistory">兑换记录</div>
        <div class="qyw-jifen-history-2" @click="handleguize">活动规则</div>
      </div>
    </div>
    <div v-if="!jifen">
      <div class="qyw-coupon">
        <input placeholder-class="qyw-input-placeholder" class="qyw-input" placeholder="请输入手机号" v-model="phone" />
      </div>
      <div class="qyw-history" @click="handHistory">兑换记录</div>

      <div class="qyw-button" @click="query"></div>

      <div class="qyw-bottom">
        <van-checkbox v-model="agree" checked-color="#58BE6A" shape="square">我已阅读并同意
          <div class="qyw-checkbox-guize" @click.stop="handlexieyi">
            《用户协议》
          </div>
          <div class="qyw-checkbox-guize" @click.stop="handleguize">
            《活动规则》
          </div>
        </van-checkbox>
      </div>
    </div>
    <div class="qyw-tishi" v-if="showTishi">
      <div class="qyw-tishi-1">使用提示</div>
      <div class="qyw-tishi-2">1、兑换成功后可领取优惠券</div>
      <div class="qyw-tishi-3">2、优惠券有效期为14天，可叠加使用</div>
      <div class="qyw-tishi-4">3、如有问题请联系官方客服 400-001-2575</div>
    </div>

    <!-- 协议组件 -->
    <xieyi v-if="isShowxieyi" @bgclicked="xybgclicked"></xieyi>

    <!-- 规则组件 -->
    <guize v-if="isShowguize" @bgclicked="gzbgclicked"></guize>
  </div>
</template>

<script>
import Vue from "vue";
import wxutils from '@/utils/Wx'
import guize from "../guize.vue";
import xieyi from "../xieyi.vue";
import { Checkbox, CheckboxGroup } from "vant";
import { Toast, Dialog } from "vant";
import { isPhoneNumber } from "@/utils/util";
import hhstore from "@/utils/HHStore.vue";
import urlParams from "@/utils/UrlParams";
import sms from "../sms.vue";
import config from "@/utils/config";
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
export default {
  components: { guize, xieyi, sms },
  props: {
    jifen: {
      type: Number,
      default: null,
    },
    goods: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      couponStyle: "",
      phone: "",
      agree: true,
      isShowxieyi: false,
      isShowguize: false,
    };
  },
  computed: {
    dhme() {
      return this.goods.reduce(
        (acc, cur) => acc + parseInt(cur.coupon_amount) / 100,
        0
      );
    },
    dhjf() {
      return this.goods.reduce((acc, cur) => acc + parseInt(cur.goodsPrice), 0);
    },
    dhqt() {
      return this.goods.reduce((acc, cur) => acc + parseInt(cur.qtjifen), 0);
    },
    showTishi() {
      const aspectRatio = window.innerWidth / window.innerHeight;
      return aspectRatio < 0.47;
    },
  },
  mounted() {
    if (hhstore.get("tmp_phone")) {
      this.phone = hhstore.get("tmp_phone");
    } else if (hhstore.get("phone")) {
      this.phone = hhstore.get("phone");
    }

    wxutils.auth(config.wx_appid);

  },
  methods: {
    // 查询用户积分
    async query() {
      if (!this.agree) {
        Toast.fail("请先阅读并同意活动规则~");
        return;
      }

      if (!isPhoneNumber(this.phone)) {
        Toast.fail("手机号填写不正确");
        return;
      }
      this.$emit("query", this.phone);
    },
    
    handlekefu() {
      window.location.href =
        "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97832154c3744d9bec1ce2e761e9194029dcd621e4cbceb822e6cd587a8f3ed33e78f6af9dc6c79786d362ef01848a65fd";
    },
    /* 点击兑换记录 */
    handHistory() {
      this.$emit("historyClick");
    },
    switchclicked() {
      this.$emit("switchclicked");
    },
    handleguize() {
      this.isShowguize = true;
    },
    handlexieyi() {
      this.isShowxieyi = true;
    },
    gzbgclicked() {
      console.info(111);
      this.isShowguize = false;
    },
    xybgclicked() {
      this.isShowxieyi = false;
    },
    handleResend() {
      this.$emit("resend");
    },
    handletapok(sms) {
      this.$emit("tapok", sms);
    },
  },
};
</script>

<style scoped>
.qyw-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url("//img.zhsyh.cn/donglian/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: scroll;
}

.qyw-kefu {
  position: absolute;
  width: 2.625rem;
  height: 2.625rem;
  bottom: 3.125rem;
  right: 1.25rem;
}

.qyw-top-left {
  margin-top: 2.5rem;
  margin-left: 1.2812rem;
  width: 5.0625rem;
  height: 0.9375rem;
}

.qyw-top {
  margin-top: calc(3vh);
  margin-left: 3.5rem;
  width: 18.7812rem;
  height: 8.8125rem;
}

.qyw-coupon {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 2.2188rem;
  margin-left: 3.7438rem;
  width: 17.75rem;
  height: 11.625rem;
  position: relative;
  background-image: url("//img.zhsyh.cn/donglian/coupon4.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.qyw-input {
  background-color: #ffffff;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  /* position: absolute; */
  margin-bottom: 0.2188rem;
  margin-left: 1px;
  /* margin-left: 0.3438rem; */
  width: 17.2rem;
  padding: 0px;
  border-width: 0px;
  height: 2.8125rem;
  text-align: center;
  font-size: 1rem;
  color: #666;
  font-weight: normal;
}

.qyw-history {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: #e43f2c;
  text-align: center;
  margin-top: 0.625rem;
  /* letter-spacing: 0.1875rem; */
}

.qyw-history-1 {}

.qyw-history-2 {
  margin-left: 1.25rem;
}

.qyw-input-placeholder {
  color: #a2a2a2;
}

/* 定义动画 */
@keyframes zoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.qyw-button {
  margin-top: 1.0938rem;
  margin-left: auto;
  margin-right: auto;
  width: 13.8125rem;
  height: 2.5rem;

  background-color: rgba(0, 0, 0, 0);
  background-image: url("//img.zhsyh.cn/donglian/btn.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  /* 添加动画 */
  animation: zoom 0.6s ease-in-out alternate infinite;
}

.qyw-checkbox {
  color: #fff;
  font-size: 0.7812rem;
  text-align: center;
}

.qyw-checkbox-guize {
  display: inline;
  font-size: 0.7812rem;
  color: #ffe8bf;
  margin-bottom: 0.125rem;
}

checkbox .wx-checkbox-input {
  width: 1.25rem;
  height: 1.25rem;
  border: 0;
  border-radius: 0.3125rem;
}

.qyw-bottom {
  text-align: center;
  margin-top: 1.875rem;
  /* margin-bottom: 1.8750rem; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.qyw-jifen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qyw-jifen-info {
  color: #fff;
  font-size: 0.7812rem;
  margin-top: 1.5938rem;
}

.qyw-jifen-info-2 {
  color: #d04d39;
  font-size: 0.875rem;
  margin-left: 0.125rem;
}

.qyw-jifen-info-3 {
  margin-left: 0.625rem;
}

.qyw-jifen-info-4 {}

.qyw-jifen-bg {
  margin-top: 0.75rem;
  border-color: #f8ddb7;
  border-style: solid;
  border-width: 0.1562rem;
  border-radius: 0.625rem;
  width: 17.4375rem;
  height: 10rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@font-face {
  font-family: "Bahnschrift";
  src: url("https://img.zhsyh.cn/donglian/bahnschrift.ttf");
}

.qyw-jifen-title {
  margin-top: 1.4375rem;
  font-size: 1rem;
  color: #d04d39;
}

.qyw-jifen-price {
  display: flex;
  align-items: center;
  color: #d04d39;
  font-family: "Bahnschrift";
  font-size: 3.75rem;
  margin-top: 0.625rem;
}

.qyw-jifen-price-yuan {
  font-size: 1.5625rem;
  margin-left: 0.1562rem;
}

.qyw-jifen-score {
  color: #2b2b2b;
  font-size: 0.6875rem;
}

.qyw-jifen-btn {
  position: absolute;
  bottom: 0rem;

  left: 0rem;
  height: 2.4375rem;
  background-color: #d04d39;
  width: 100%;
  text-align: center;
  line-height: 2.4375rem;
  color: white;
  font-size: 1rem;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
}

.qyw-tishi {
  display: flex;
  flex-direction: column;
  margin-left: 3.2812rem;
  margin-top: 3.4375rem;
  font-size: 0.8125rem;
  color: white;
}

.qyw-tishi-1 {
  font-size: 0.9375rem;
  color: #f5ddba;
}

.qyw-tishi-2 {}

.qyw-tishi-3 {}

.qyw-tishi-4 {}

.qyw-jifen-history {
  display: flex;
  margin-top: 0.5625rem;
  font-size: 0.9375rem;
  color: #d04d39;
}

.qyw-jifen-history-1 {}

.qyw-jifen-history-2 {
  margin-left: 1.5625rem;
}
</style>
<style>
.van-checkbox__label {
  color: white;
  font-size: 0.7812rem;
}

.van-checkbox__icon {
  background-color: white;
}
</style>