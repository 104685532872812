// toast.js

import Vue from 'vue'
import LoadingComponent from './Loading.vue'

const LoadingConstructor = Vue.extend(LoadingComponent)

let instance


function initInstance() {
  instance = new LoadingConstructor({
    el: document.createElement('div')
  })
  document.body.appendChild(instance.$el)
}

function showLoading(options) {
  if (!instance) {
    initInstance()
  }

  instance.show = true
}

function hideLoading() {
  if (instance) {
    instance.show = false
  }
}

Vue.prototype.$loading = showLoading

export {
  initInstance,
  showLoading,
  hideLoading
}
