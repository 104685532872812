<template>
  <van-popup v-model="show" @close="handclose" round>
    <div class="orders-container">
      <div class="orsers-close" @click="handclose">
        <img src="//img.zhsyh.cn/donglian/close.png" />
      </div>
      <div class="orders-title">兑换记录</div>
      <div class="orders-empty" v-if="!orderlist.length">
        暂无购买记录
      </div>
      <div class="orders-list" v-if="orderlist.length">
        <div class="orders-list-item" @click="handHistoryClick(order)" v-for="(order, index) in orderlist" :key="index">
          <div class="orders-list-item-top">
            <div class="orders-list-item-top-left">{{ order.title }}</div>
            <div class="orders-list-item-top-right">{{ order.showTime }}</div>
          </div>

          <div class="orders-list-item-bottom">
            <div class="orders-list-item-bottom-left">
              {{ order.phone }} | {{ order.points }}积分
            </div>
            <div class="orders-list-item-bottom-right" :style="{ color: getColor(order.status, order.received) }">
              {{ getStatus(order.status, order.received) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

import config from "../utils/config";
import { Toast, Dialog } from "vant";
import { Popup } from "vant";
import hhstore from "../utils/HHStore";

Vue.use(Popup);
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible() {
      this.show = this.visible;
      if (this.show) {
        this.getList();
      }
    },
  },
  data() {
    return {
      show: false,
      orderlist: [],
    };
  },
  computed: {},
  mounted() { },
  methods: {
    ...mapActions(["post", "syncpost"]),

    async getList() {
      try {
        var res = await this.syncpost({
          url: "/jfdh/orderList",
          data: {

          },
        });
      } catch (error) {
        Toast.fail(e.message);
        return;
      }
      this.orderlist = res;
    },
    handclose() {
      this.$emit("update:visible", false);
    },
    handHistoryClick(order) {
      this.$router.push(
        `/detail?goodOrderId=${order.goodOrderId}`
      );
      this.$emit("bgclicked", 1);
    },

    getGoodsName(order) {
      var data = JSON.parse(order.goodsInfo);
      return data.goodslist[0].showName;
    },

    getStatus(status, received) {
      switch (status) {
        // 0付款成功，1部分退款，2全部退款，3退款失败，4未付款，5订单异常
        case "0": {
          if (received == 1) {
            return "未领取";
          } else {
            return "已领取";
          }
        }
        case "1":
          return "部分退款";
        case "2":
          return "全部退款";
        case "3":
          return "退款失败";
        case "4":
          return "未付款";
        case "5":
          return "订单异常";
        default:
          return "未知状态";
      }
    },

    getColor(status, received) {
      switch (status) {
        // 0付款成功，1部分退款，2全部退款，3退款失败，4未付款，5订单异常
        case "0": {
          if (received == 1) {
            return "#D84836";
          } else {
            return "#166F31";
          }
        }
        default:
          return "#D84836";
      }
    },
  },
};
</script>

<style scoped>
/* components/orders/orders.wxss */
.orders-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  width: 70vw;
  max-width: 16rem;
  height: 15.625rem;
  position: relative;
}

.orsers-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.orsers-close img {
  width: 1.15625rem;
  height: 1rem;
}

.orders-title {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1.28125rem;
  color: #333;
}
.orders-empty{
  text-align: center;
  margin-top: 5rem;
  color: #ccc;
  font-size: .875rem;
}
.orders-list {
  margin-top: 0.625rem;
  /* background-color: red; */
  height: 13.125rem;
  overflow-y: scroll;
}

.orders-list-item {
  border-bottom-style: solid;
  border-bottom-width: 0.03125rem;
  border-bottom-color: #d8d8d8;
  padding: 0.53125rem 0rem;
}

.orders-list-item-top {
  display: flex;
  justify-content: space-between;
}

.orders-list-item-top-left {
  font-size: 0.9375rem;
  font-weight: bold;
  color: #333;
}

.orders-list-item-top-right {
  font-size: 0.75rem;
  color: #333;
  font-weight: normal;
}

.orders-list-item-bottom {
  margin-top: 0.375rem;
  display: flex;
  justify-content: space-between;
}

.orders-list-item-bottom-left {
  color: #c0c0c0;
  font-size: 0.75rem;
}

.orders-list-item-bottom-right {
  font-size: 0.75rem;
}
</style>