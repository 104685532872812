(function (doc, win) {
  var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      var clientHeight = docEl.clientHeight;
      if (!clientWidth) return;
      if (!clientHeight) return
      //固定宽度750px 基准像素30px
      // alert(clientWidth+','+clientHeight)
      var width = clientHeight > clientWidth ? clientWidth : clientHeight

      let fontSize = 30 * (width / 750);

      // 获取用户代理字符串
      var userAgent = navigator.userAgent;

      // 使用正则表达式检测是否包含手机设备的关键词
      var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

      // 根据检测结果执行不同的操作
      if (isMobile) {
        docEl.style.fontSize = fontSize + 'px';
      } else {
        docEl.style.fontSize = Math.min(fontSize, 18) + 'px';
        console.info(docEl.style.fontSize)
      }

    };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);
