<template>
  <div>
    <!-- 倒计时秒数，通过组件的属性传入 -->
    <div class="countdown-text" v-if="!showResend">
      {{ remainSeconds }}后重新发送...
    </div>
    <!-- 判断是否显示重新获取验证码的按钮 -->
    <div class="countdown-button" v-if="showResend" @click="handleResend">
      点击重新获取验证码
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";

export default {
  props: {
    // 倒计时秒数
    countdown: {
      // type: Number,
      default: 60, // 默认为60秒
    },
  },
  data() {
    return {
      // 倒计时剩余秒数
      remainSeconds: 0,
      // 是否显示重新获取验证码的按钮
      showResend: false,
      // 计时器对象
      timer: null,
    };
  },
  mounted() {
    this.remainSeconds = this.countdown;

    this.startCountdown();
  },
  unmounted() {
    clearInterval(this.timer);
  },
  computed: {},
  methods: {
    // 开始倒计时
    startCountdown() {
      this.remainSeconds = this.remainSeconds;

      // 设置计时器
      this.timer = setInterval(() => {
        // 剩余秒数减一
        this.remainSeconds = this.remainSeconds - 1;
        // 如果倒计时结束，清除计时器并显示重新获取验证码的按钮
        if (this.remainSeconds <= 0) {
          clearInterval(this.timer);
          this.showResend = true;
        }
      }, 1000);
    },
    // 重新获取验证码
    handleResend() {
      console.info('handleResend')
      // 重置剩余秒数，并重新开始倒计时
      this.$emit("resend");

      this.remainSeconds = this.countdown;
      this.showResend = this.false;

      this.startCountdown();
    },
  },
};
</script>

<style scoped>
.countdown-text {
  color: #d94f3e;
  font-size: 0.8125rem;
}

.countdown-button {
  color: #d94f3e;
  font-size: 0.8125rem;
}
</style>