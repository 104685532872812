<template>
  <!--components/guize/guize.wxml-->
  <van-popup v-model="show" round @close="handclose">
    <div class="xieyi-container">
      <div class="xieyi-close" @click="handclose">
        <img src="//img.zhsyh.cn/donglian/close.png"></img>
      </div>
      <div class="xieyi-title">用户协议</div>
      <div class="xieyi-content">
        <pre class="xieyi-pre">
{{ xieyi }}
        </pre>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from "vue";

import config from "../utils/config";
import { Toast, Dialog } from "vant";
import { Popup } from "vant";
import { mapActions, mapState } from "vuex";

Vue.use(Popup);

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
      style: config.style(),
      xieyi: ''
    };
  },
  mounted() {
    this.limitRegion();
  },
  methods: {
    ...mapActions(["post", "syncpost"]),
    bgclicked() {
      // this.triggerEvent('bgclicked', 1)
    },

    handclose() {
      this.$emit("bgclicked", 1);
    },
    limitRegion() {
      this.post({
        url: "/jfdh/qt_limitRegion",
        data: {

        },
        success: (res) => {
          const lr = res.join(',')
          // console.info(config.xieyi)
          this.xieyi = this.style.xieyi.replace('北京', lr)
        },
        error: (code, err) => {

        },
      });
    }
  },
};
</script>

<style scoped>
/* components/guize/guize.wxss */
.xieyi-container {
  padding: 20px;
  width: 70vw;
  max-width: 16rem;
  position: relative;
}

.xieyi-title {
  text-align: center;
  font-size: 1.25000rem;
}

.xieyi-content {
  /* margin-top: 1.25000rem; */
  font-size: 0.87500rem;
  height: 23vh;
  overflow-y: scroll;
  width: 100%;
}

.xieyi-close {
  position: absolute;
  right: 1.00000rem;
  top: 1.00000rem;
}

.xieyi-close img {
  width: 1.15625rem;
  height: 1.00000rem;
}

.xieyi-pre {
  white-space: pre-wrap;
  width: 100%;
}
</style>
