<template>
  <van-popup v-model="show" round :style="{ '--p1color--': style.p1color }">
    <div class="vode-container">
      <div class="vode-title">{{ title }}</div>
      <div class="vode-text">验证码已发送至：</div>
      <div class="vode-tishi">+86 {{ phone }}</div>
      <div class="vode-yzm">
        <input placeholder-class="vcode-input-placeholder" type="number" maxlength="6" placeholder="请输入验证码"
          v-model="sms" />
      </div>
      <countdown @resend="handleResend" v-if="show" class="vode-daojishi" countdown="60"></countdown>
      <div class="vode-buttons">
        <div @click="handlecancel" class="vode-cancel">取消</div>
        <div @click="handleok" class="vode-ok">确定</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from "vue";

import config from "../utils/config";
import { Toast, Dialog } from "vant";
import { Popup } from "vant";

import countdown from "./countdown";

Vue.use(Popup);
export default {
  components: { countdown },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '验证手机号',
    },
    phone: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sms: "",
      style: config.style(),
    };
  },
  watch: {
    show(nvalue, oldValue) {
      // 属性值发生变化时执行的操作
      if (!nvalue) this.sms = ''
    }
  },
  computed: {},
  methods: {
    bgclicked() {
      // this.triggerEvent('bgclicked', 1)
    },
    /* 输入验证码的事件 */

    handleResend() {
      console.info('handleResend')
      this.$emit("resend");
    },
    handlecancel() {
      this.$emit("bgclicked", 1);
    },
    handleok() {
      const reg = /^\d{6}$/;
      if (!reg.test(this.sms)) {
        Toast({
          message: "验证码格式不正确",
          icon: null,
        });

        return;
      }
      console.info(this.sms);
      this.$emit("tapok", this.sms);
    },
  },
};
</script>

<style scoped>
.vode-container {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  padding: 20px;
  width: 70vw;
  max-width: 16rem;
}

.vode-title {
  text-align: center;
  font-size: 1.28125rem;
  color: #333;
}

.vode-text {
  margin-top: 0.75rem;
  font-size: 0.9375rem;
  color: #979797;
}

.vode-tishi {
  margin-top: 0.25rem;
  font-size: 1.2rem;
  color: #333;
}

.vode-yzm {
  margin-top: 0.625rem;
  /* border-bottom: solid;
  border-width: 0.03125rem;
  border-color: #888; */
  padding: 0.3125rem;
  color: #333;

}

input::placeholder {
  color: #ccc;
}

.vode-yzm input {
  width: 98%;
  /* height: 30px; */
  text-align: center;
  border-width: 1px;
  border-color: #C1C2BC;
  background-color: #fff;
  border-style: solid;

  height: 2.5rem;
  border-radius: .625rem;
  /* text-align: center; */
  font-size: 1.125rem;
}

.vcode-input-placeholder {
  color: #c0c0c0;
}

.vode-buttons {
  margin-top: 1.5625rem;
  display: flex;
  gap: 1.625rem;
  justify-content: center;
}

.vode-daojishi {
  margin-top: 0.75rem;
}

.vode-ok {
  height: 2.125rem;
  width: 6.875rem;
  text-align: center;
  background-color: var(--p1color--);
  color: white;
  border-radius: 1.0625rem;
  line-height: 2.125rem;
  font-size: 1.125rem;
}

.vode-cancel {
  font-size: 1.125rem;
  line-height: 2.125rem;
  height: 2.125rem;
  width: 6.875rem;
  text-align: center;
  background-color: #d3d3d3;
  color: white;
  border-radius: 1.0625rem;
}
</style>