<template>
  <!--components/guize/guize.wxml-->
  <van-popup v-model="show" round @close="handclose">
    <div class="guize-container">
      <div class="guize-close" @click="handclose">
        <img src="//img.zhsyh.cn/donglian/close.png"></img>
      </div>
      <div class="guize-title">使用说明</div>
      <div class="guize-content">
        <pre class="guize-pre">
1、兑换成功后，将会以微信代金券的形式发放;
2、使用微信支付时，支付金额大于代金券面额即可使用，多张代金券可叠加使用; 
3、如有疑问，可以拨打客服热线：4000012575;
        </pre>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from "vue";

import config from "../utils/config";
import { Toast, Dialog } from "vant";
import { Popup } from "vant";

Vue.use(Popup);

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
      style: config.style(),
    };
  },
  methods: {
    bgclicked() {
      // this.triggerEvent('bgclicked', 1)
    },

    handclose() {
      this.$emit("bgclicked", 1);
    },
  },
};
</script>

<style scoped>
/* components/guize/guize.wxss */
.guize-container {
	padding: 20px;
  width: 70vw;
  max-width: 16rem;
  position: relative;
}
.guize-title {
  text-align: center;
  font-size: 1.25000rem;
}

.guize-content {
  /* margin-top: 1.25000rem; */
  font-size: 0.87500rem;
  height: 23vh;
  overflow-y: scroll;
  width: 100%;
}

.guize-close {
  position: absolute;
  right: 1.00000rem;
  top: 1.00000rem;
}

.guize-close img {
  width: 1.15625rem;
  height: 1.00000rem;
}

.guize-pre {
  white-space: pre-wrap;
  width: 100%;
  line-height: 1.5; /* 设置行间距为文本大小的 1.5 倍 */

}
</style>
