import hhstore from './HHStore'

// 定义测试环境和线上环境的API地址
const API_TEST = 'https://test.zhsyh.cn'
const API_TEST_APPID = 'donbe'
const API_TEST_KEY = 'e10adc3949ba59abbe56e057f20f883e'

const API_PROD = 'https://api.zhsyh.cn'
const API_PROD_APPID = 'donbe'
const API_PROD_KEY = '737zLrJLKCpQjHXbo'

// 畅游的授权小程序appid
const AUTH_APPID_TEST = 'wx056cd791c3374985'
// const AUTH_APPID_TEST = 'wx6f4c2b55473ec30a'
const AUTH_APPID_PROD = 'wx6f4c2b55473ec30a'



const FMOPT_TEST = {
  fpHost: "fptest.tongdun.net",
}
const FMOPT_PROD = {
  fpHost: "fp.tongdun.net",
}

const minidata = {
  qyw: {
    lujing: "权益王-移动积分一兑换记录",
    p1color: '#D14D38', //主色调
    img1: '//img.zhsyh.cn/donglian/tips2.jpg', //确认支付的时候提示图片
    day: 14,
    shuoming: "代金券已发放，微信支付时可直接抵扣",
    xieyi: "1、兑换后权益王现金券即时到账\n\
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换权益王现金券。 \n\
5、该券为权益王现金券，用户应在权益王允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。\n\
7、基于现金券特殊性，一经售出概不退换；现金券不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。\n\
8、请注意 权益王现金券自领取后14天内有效，过期未使用自动作废。\n\
9、使用门槛：现金券金额大于优惠券金额才可用；比如2元现金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  },
  rongxin: {
    lujing: "首页一兑换记录",
    p1color: '#D14D38', //主色调
    img1: '//img.zhsyh.cn/rongxin/tips2.png', //确认支付的时候提示图片
    day: 14,
    shuoming: "立减金已发放，微信支付时可直接抵扣",
    xieyi: "1、兑换后立减金即时到账\n\
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换权益王现金券。 \n\
5、该券为融信立减金，用户应在融信允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。\n\
7、基于立减金特殊性，一经售出概不退换；立减金不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。\n\
8、请注意 立减金自领取后14天内有效，过期未使用自动作废。\n\
9、使用门槛：立减金金额大于优惠券金额才可用；比如2元现金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  },
  qianliao: {
    lujing: "首页一兑换记录",
    p1color: '#D14D38', //主色调
    img1: '//img.zhsyh.cn/rongxin/tips2.png', //确认支付的时候提示图片
    day: 14,
    shuoming: "立减金已发放，微信支付时可直接抵扣",
    xieyi: "1、兑换后立减金即时到账\n\
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换权益王现金券。 \n\
5、该券为兴趣岛立减金，用户应在兴趣岛允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。\n\
7、基于立减金特殊性，一经售出概不退换；立减金不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。\n\
8、请注意 立减金自领取后14天内有效，过期未使用自动作废。\n\
9、使用门槛：立减金金额大于优惠券金额才可用；比如2元现金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  },
  haishi: {
    lujing: "首页一兑换记录",
    p1color: '#D14D38', //主色调
    img1: '//img.zhsyh.cn/rongxin/tips2.png', //确认支付的时候提示图片
    day: 14,
    shuoming: "立减金已发放，微信支付时可直接抵扣",
    xieyi: "1、兑换后立减金即时到账\n\
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换现金券。 \n\
5、该券为海石立减金，用户应在海石允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。\n\
7、基于立减金特殊性，一经售出概不退换；立减金不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。\n\
8、请注意 立减金自领取后14天内有效，过期未使用自动作废。\n\
9、使用门槛：立减金金额大于优惠券金额才可用；比如2元现金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  },
  jnhl: {
    lujing: "首页一兑换记录",
    p1color: '#D14D38', //主色调
    img1: '//img.zhsyh.cn/rongxin/tips2.png', //确认支付的时候提示图片
    homebg: '//img.zhsyh.cn/donglian/jnhl/bg.jpg', //确认支付的时候提示图片    
    day: 7,
    shuoming: "立减金已发放，微信支付时可直接抵扣",
    xieyi: "1、兑换后立减金即时到账\n\
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换现金券。 \n\
5、该券为济南华联立减金，用户应在允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。\n\
7、基于立减金特殊性，一经售出概不退换；立减金不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。\n\
8、请注意 立减金自领取后7天内有效，过期未使用自动作废。\n\
9、使用门槛：立减金金额大于优惠券金额才可用；比如2元现金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  },
  hualian: {
    lujing: "首页一兑换记录",
    p1color: '#D14D38', //主色调
    img1: '//img.zhsyh.cn/rongxin/tips2.png', //确认支付的时候提示图片
    day: 14,
    shuoming: "立减金已发放，微信支付时可直接抵扣",
    xieyi: "1、兑换后立减金即时到账\n\
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换权益王现金券。 \n\
5、该券为华联超市立减金，用户应在华联超市允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。\n\
7、基于立减金特殊性，一经售出概不退换；立减金不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。\n\
8、请注意 立减金自领取后14天内有效，过期未使用自动作废。\n\
9、使用门槛：立减金金额大于优惠券金额才可用；比如2元现金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  },ylw: {
    lujing: "首页一兑换记录",
    p1color: '#D14D38', //主色调
    img1: '//img.zhsyh.cn/rongxin/tips2.png', //确认支付的时候提示图片
    day: 14,
    shuoming: "立减金已发放，微信支付时可直接抵扣",
    xieyi: "1、兑换后立减金即时到账\n\
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换权益王现金券。 \n\
5、该券为易乐玩立减金，用户应在易乐玩允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。\n\
7、基于立减金特殊性，一经售出概不退换；立减金不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。\n\
8、请注意 立减金自领取后14天内有效，过期未使用自动作废。\n\
9、使用门槛：立减金金额大于优惠券金额才可用；比如2元现金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  },
  "206497": {
    lujing: "权益王-移动积分一兑换记录",
    p1color: '#D14D38', //主色调
    img1: '//img.zhsyh.cn/donglian/tips2.jpg', //确认支付的时候提示图片
    day: 14,
    shuoming: "代金券已发放，微信支付时可直接抵扣",
    xieyi: "1、兑换后权益王现金券即时到账\n\
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换权益王现金券。 \n\
5、该券为权益王现金券，用户应在权益王允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。\n\
7、基于现金券特殊性，一经售出概不退换；现金券不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。\n\
8、请注意 权益王现金券自领取后14天内有效，过期未使用自动作废。\n\
9、使用门槛：现金券金额大于优惠券金额才可用；比如2元现金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  },
  jtc: {
    lujing: "捷停车公众号-个人中心-优惠券-立即使用",
    p1color: '#EC6930',
    img1: '//img.zhsyh.cn/donglian/jtc/3.jpg', //确认支付的时候提示图片
    day: 7,
    shuoming: "优惠券已发放到捷停车公众号内",
    xieyi: "1、兑换后停车立减券即时到账（当天可用），查看方式：捷停车公众号-个人中心-优惠券-立即使用。\n \
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换微信停车立减券。 \n\
5、该券为捷停车代金券，用户应在捷停车允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。 \n\
7、基于现金券特殊性，一经售出概不退换；现金券不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。 \n\
8、请注意 停车立减券自领取后7天内有效，过期未使用自动作废。 \n\
9、使用门槛：现金券金额大于优惠券金额才可用；比如2元现金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  },
  zyly: {
    lujing: "卓越绿园APP-我的-优惠券，查看可用优惠券记录",
    p1color: '#68B878',
    img1: '//img.zhsyh.cn/donglian/zyly/lc.png', //确认支付的时候提示图片
    day: 7,
    shuoming: "优惠券已发放到卓越绿园APP内",
    xieyi: "1、兑换后立减券即时到账（当天可用），查看方式：卓越绿园APP-我的-优惠券，查看可用优惠券记录。\n \
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换代金券。 \n\
5、该券为卓越绿园代金券，用户应在卓越绿园允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。 \n\
7、基于代金券特殊性，一经售出概不退换；代金券不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。 \n\
8、请注意 代金券自领取后7天内有效，过期未使用自动作废。 \n\
9、使用门槛：支付金额大于代金券金额才可用；比如2元代金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575 \n\
11、点击\"立即兑换\"，系统将自动为您注册绿园账号，欢迎登录使用卓越绿园APP进行充电，购物！"
  },
  demo: {
    lujing: "APP-我的-优惠券，查看可用优惠券记录",
    p1color: '#68B878',
    img1: '//img.zhsyh.cn/donglian/demo/1.png', //确认支付的时候提示图片
    day: 7,
    shuoming: "优惠券已发放到APP内",
    xieyi: "1、兑换后立减券即时到账（当天可用），查看方式：APP-我的-优惠券，查看可用优惠券记录。\n \
2、本活动仅限中国移动用户参与。 \n\
3、北京的移动用户积分暂不支持兑换。 \n\
4、用户根据界面引导操作，即可使用移动积分兑换代金券。 \n\
5、该券为代金券，用户应在允许范围内进行使用。 \n\
6、请确保使用本人手机、本人手机号码、本人微信号进行操作。 \n\
7、基于代金券特殊性，一经售出概不退换；代金券不兑现金不找零，超额需补差价，抵用现金不可开发票；积分一旦兑换，不可回退，请在兑换前确认，谨慎兑换。 \n\
8、请注意 代金券自领取后7天内有效，过期未使用自动作废。 \n\
9、使用门槛：支付金额大于代金券金额才可用；比如2元代金券，需满2元可用。 \n\
10、用户按页面提示操作即可完成兑换，如有问题可查看客户答疑和流程说明或咨询客服4000012575"
  }
}


// 导出API地址
export default {
  // 根据当前环境选择API地址
  // develop：开发版
  // trial：体验版
  // release：正式版
  api: process.env.NODE_ENV === 'production' ? API_PROD : API_TEST,
  api_appid: process.env.NODE_ENV === 'production' ? API_PROD_APPID : API_TEST_APPID,
  api_key: process.env.NODE_ENV === 'production' ? API_PROD_KEY : API_TEST_KEY,

  auth_appid: process.env.NODE_ENV === 'production' ? AUTH_APPID_PROD : AUTH_APPID_TEST,
  fmOpt: process.env.NODE_ENV === 'production' ? FMOPT_PROD : FMOPT_TEST,
  wx_appid: 'wx70d64d084ece3234',
  
  style() {
    const ch = hhstore.get('ch')
    return minidata[ch]
  }
}