<script>
import urlParams from './UrlParams'
import {
	mapActions,
	mapState
} from 'vuex'
import hhstore from './HHStore'
import axios from 'axios';
import 'vant/lib/index.css'
import { postf } from '../store'

export default {
	async auth(wxappid) {

		const UA = navigator.userAgent.toLowerCase()
		const inWechat = UA.match(/.*?(micromessenger\/([0-9.]+))\s*/)
		if (!inWechat) return;

		if (!hhstore.get("openid")) {

			//尝试微信code登录
			var code = urlParams.get('code');
			if (code) {
				await this.code2openid(code);
			} else {
				window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + wxappid +
					"&redirect_uri=" + encodeURIComponent(window.location.origin + window.location.pathname + window
						.location.search) + "&response_type=code&scope=snsapi_base#wechat_redirec";
			}
		}
	},
	async code2openid(code, callback = function () { }) {
		try {
			var data = await postf({
				url: "/jfdh/code2openid",
				data: {
					"code": code,
				}
			})
		} catch (error) {
			callback(false)
			console.log(error);
			return
		}
		hhstore.set("openid", data)

		callback(data)
	}
}
</script>
