<template>
  <div class="jtc-container">
    <div class="jtc-scroll">
      <div class="jtc-topsolg">
        <img src="http://img.zhsyh.cn/donglian/zyly/top.png" />
      </div>
      <div v-if="jifen" class="jtc-jifen">
        <div class="jtc-jifen-info">
          <span class="jtc-jifen-info-1">{{ phone }}</span>
          <span class="jtc-jifen-info-2" @click="switchclicked">切换</span>
          <span class="jtc-jifen-info-3">移动积分：</span>
          <span class="jtc-jifen-info-4">{{ jifen }}</span>
        </div>
        <div class="jtc-jifen-bg">
          <div class="qparking-jifen-top">您最高可兑换
            <span class="qparking-jifen-top-price">{{ dhzd }}</span>
            元券包
          </div>

          <div class="qparking-customs">
            <div class="qparking-custom" v-for="(item, index) in selectGoods" :key="index">
              <div class="qparking-custom-left">{{ item.coupon_amount / 100 }}<span
                  class="qparking-custom-left-2">元</span>
              </div>
              <div class="qparking-custom-right">
                <span class="qparking-custom-right-text">立减代金券</span>
                <div class="qparking-custom-minus" @click="handleminus(item)"></div>
                <span class="qparking-custom-right-num">{{ item.num }}</span>
                <div class="qparking-custom-plus" @click="handleplus(item)"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="qparking-jifen-title">
          <span v-if="dhjf > jifen">积分不足{{ dhjf }}，无法兑换</span>
          <span v-else-if="dhzd < dhme">超出最大兑换面额</span>
          <span v-else>您本次兑换<span class="qparking-jifen-title-num">{{ dhme }}</span>元（消耗<span
              class="qparking-jifen-title-num">{{ dhjf }}</span>移动积分，<span class="qparking-jifen-title-num">{{ dhqt
              }}</span>蜻蜓积分）</span>
        </div>
        <div class="qparking-jifen-btn" :class="{ 'qparking-jifen-btn-dis': dhzd < dhme || dhjf > jifen || dhme == 0 }"
          @click="duihuanclicked">立即兑换</div>

        <div class="jtc-jifen-history">
          <div class="jtc-jifen-history-1" @click="handHistory">兑换记录</div>
          <div class="jtc-jifen-history-2" @click="handleguize">活动规则</div>
        </div>
      </div>
      <div v-else class="jtc-coupon-contain">
        <div class="jtc-coupon">
          <div class="jtc-coupon-1">最多可兑</div>
          <div class="jtc-coupon-2">
            <img src="http://img.zhsyh.cn/donglian/zyly/bg.png" />
          </div>
          <div class="jtc-coupon-desc">兑换成功即可使用</div>
        </div>

        <div class="jtc-input-content">
          <input placeholder-class="jtc-input-placeholder" class="jtc-input" placeholder="请输入手机号" v-model="phone" />
        </div>

        <div class="jtc-button" @click="query">
          <img src="http://img.zhsyh.cn/donglian/zyly/btn.png" />
        </div>
        <div class="jtc-history" @click="handHistory">兑换记录</div>
        <div class="jtc-bottom">
          <van-checkbox v-model="agree" checked-color="#58BE6A" shape="square"><span
              class="jtc-bottom-agree">我已阅读并同意</span>
            <div class="jtc-checkbox-guize" @click.stop="handlexieyi">
              《用户协议》
            </div>
            <div class="jtc-checkbox-guize" @click.stop="handleguize">
              《活动规则》
            </div>
          </van-checkbox>

          <van-checkbox v-model="agreezy" checked-color="#58BE6A" shape="square"><span
              class="jtc-bottom-agree">若未注册卓越绿园APP，系统将使用您输入的手机号自动为您创建卓越绿园APP账号。卓越绿园APP相关协议</span>
            <div class="jtc-checkbox-guize" @click.stop="handlezy1">
              《用户协议》
            </div>
            <div class="jtc-checkbox-guize" @click.stop="handlezy2">
              《隐私协议》
            </div>
            <div class="jtc-checkbox-guize" @click.stop="handlezy3">
              《绿园园宝用户需知》
            </div>
          </van-checkbox>
        </div>
      </div>

      <div class="jtc-toplogo">
        移动&卓越绿园
      </div>

      <div class="jtc-tishi" v-if="showTishi">
        <div class="jtc-tishi-1">使用提示</div>
        <div class="jtc-tishi-2">
          1、兑换成功后优惠券当天可用，每次使用一张。
        </div>
        <div class="jtc-tishi-3">2、优惠券有效期为7天。</div>
        <div class="jtc-tishi-4">3、如有问题请联系官方客服 400-001-2575</div>
        <div class="jtc-tishi-4">4、点击"立即兑换"，系统将自动为您注册绿园账号，欢迎登录使用卓越绿园APP进行充电，购物！</div>
        
      </div>

      <img class="jtc-kefu" @click="handlekefu" src="http://img.zhsyh.cn/donglian/jtc/kefu.png" />
    </div>
    <!-- 协议组件 -->
    <xieyi v-if="isShowxieyi" @bgclicked="xybgclicked"></xieyi>

    <!-- 规则组件 -->
    <guize v-if="isShowguize" @bgclicked="gzbgclicked"></guize>

  </div>
</template>

<script>
import help from "@/utils/Help";
import hhstore from "@/utils/HHStore";
import { mapActions, mapState } from "vuex";

import Vue from "vue";
import guize from "../guize.vue";
import sms from "../sms.vue";
import xieyi from "../xieyi.vue";
import { Checkbox, CheckboxGroup } from "vant";
import { Toast, Dialog } from "vant";
import { isPhoneNumber } from "@/utils/util";
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
export default {
  components: { guize, xieyi, sms },
  props: {
    jifen: {
      type: Number,
      default: null,
    },
    goods: {
      type: Array,
      default: [],
    },
    allgoods: { // 所有的产品
      type: Array,
      value: []
    },
  },
  watch: {
    goods(nvalue, oldValue) {
      // 属性值发生变化时执行的操作
      if (!nvalue) return


      let data = this.allgoods.map(item => {
        item.num = 0
        for (const item2 of this.goods) {
          if (item.id == item2.id) {
            item.num = item.num + 1
          }

        }
        return item
      })

      this.refsgoods(data)
    }
  },
  data() {
    return {
      selectGoods: [],
      couponStyle: "",
      phone: "",
      agree: true,
      agreezy: true,
      isShowxieyi: false,
      isShowguize: false,
      showTishi: true,
    };
  },
  computed: {
    dhme() {
      return this.selectGoods.reduce((acc, cur) => acc + parseInt(cur.coupon_amount) * cur.num / 100, 0)
    },
    dhjf() {
      return this.selectGoods.reduce((acc, cur) => acc + parseInt(cur.goodsPrice) * cur.num, 0);
    },
    dhqt() {
      return this.selectGoods.reduce((acc, cur) => acc + parseInt(cur.qtjifen) * cur.num, 0);
    },
    dhzd() {
      return this.goods.reduce((acc, cur) => acc + parseInt(cur.coupon_amount) / 100, 0)
    },

  },
  mounted() {
    if (hhstore.get("tmp_phone")) {
      this.phone = hhstore.get("tmp_phone");
    }
    // this.getsrc()
  },
  methods: {
    ...mapActions(["post", "syncpost"]),
    // 获取畅由链接用的
    getsrc() {
      this.post({
        url: "/jfdh/cmcclink",
        data: {
          ch: 'zyly',
          callback: window.location.href
        },
        success: (res) => {
          window.location.href = res
        },
        error: (code, err) => {
          console.info(code, err);
          Toast.clear();
          Toast.fail(err);
        },
      });
    },
    /* 点击兑换记录 */
    handHistory() {
      this.$emit("historyClick");
    },
    handleguize() {
      this.isShowguize = true;
      help.click_log("活动规则按钮");
    },
    handlezy1() {
      help.click_log("卓越用户协议按钮");
      window.location.href = 'https://c.zhuoyuelvyuan.com/h5/#/agreement'
    },
    handlezy2() {
      help.click_log("卓越用户协议按钮");
      window.location.href = 'https://c.zhuoyuelvyuan.com/h5/#/privacy'
    },
    handlezy3() {
      help.click_log("卓越用户协议按钮");
      window.location.href = 'https://c.zhuoyuelvyuan.com/h5/#/plain'
    },
    handlexieyi() {
      help.click_log("协议按钮");
      this.isShowxieyi = true;
    },
    handlekefu() {
      window.location.href =
        "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97832154c3744d9bec1ce2e761e9194029dcd621e4cbceb822e6cd587a8f3ed33e78f6af9dc6c79786d362ef01848a65fd";
    },

    // 查询用户积分
    async query() {
      if (!this.agree) {
        Toast({
          message: "请先阅读并同意活动规则~",
          icon: null,
        });
        return;
      }

      if (!this.agreezy) {
        Toast({
          message: "请先阅读并同意活动规则~",
          icon: null,
        });
        return;
      }

      if (!isPhoneNumber(this.phone)) {
        Toast({
          message: "手机号填写不正确",
          icon: null,
        });
        return;
      }

      hhstore.set("phone", this.phone);
      this.$emit("query", this.phone);
    },
    xybgclicked() {
      this.isShowxieyi = false;
    },
    gzbgclicked() {
      this.isShowguize = false;
    },
    switchclicked() {
      this.phone = "";
      this.$emit("switchclicked");

    },
    handleResend() {
      console.info('handleResend')
      this.$emit("resend");
    },
    handletapok(sms) {
      console.info(sms)
      this.$emit("tapok", sms);
    },
    handleplus(ci) {

      let data = this.selectGoods.map(item => {
        if (item.id == ci.id) {
          item.num += 1
        }
        return item;
      })

      this.refsgoods(data)
    },
    handleminus(ci) {

      let data = this.selectGoods.map(item => {
        if (item.id == ci.id) {
          item.num = Math.max(0, item.num - 1)
        }
        return item;
      })
      this.refsgoods(data)
    },
    refsgoods(goods) {
      this.selectGoods = goods;

    },
    duihuanclicked() {
      if (this.dhzd < this.dhme) {
        return
      }
      //格式转换一下
      let data = []
      for (const item of this.selectGoods) {
        for (let index = 0; index < item.num; index++) {
          const {
            num,
            ...newObj
          } = item;
          data.push(newObj)
        }
      }
      this.$emit('duihuanclicked', { goods: data })
    },
  },
};
</script>

<style scoped>
.jtc-container {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background: linear-gradient(to bottom, #55d18b, #46ba71);

}

.jtc-scroll {
  position: relative;
  /* width: 23.4375rem; */
  width: 100vw;
  /* height: 100vh; */
  height: 51.15625rem;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.jtc-topsolg img {
  margin-top: 5rem;
  width: 23rem;
}

.jtc-toplogo {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  color: white;
  align-self: flex-start;
  display: flex;
  align-items: center;
}

.jtc-toplogo-jtc {
  margin-left: 1.5625rem;
  width: 5.125rem;
  height: 1.74375rem;
}

.jtc-toplogo-yidong {
  margin-left: 1.5625rem;
  width: 5.71875rem;
  height: 1.8125rem;
}

.jtc-kefu {
  position: absolute;
  width: 4.375rem;
  height: 2.26031rem;
  bottom: 6.1875rem;
  right: 0rem;
}

.jtc-top-left {
  margin-top: 2.5rem;
  margin-left: 1.28125rem;
  width: 5.0625rem;
  height: 0.9375rem;
}

.jtc-top {
  margin-top: calc(7vh);
  margin-left: 2.5rem;
  width: 18.78125rem;
  height: 8.8125rem;
}

.jtc-coupon-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jtc-coupon {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  margin-top: 1.75rem;
  /* margin-left: 3.4375rem; */
  width: 18.78125rem;
  height: 11.15625rem;
  position: relative;
  border-radius: 15px;
  background: linear-gradient(to bottom, #7CDC97, #69BA7A);
}

.jtc-coupon-desc {
  /* margin-top: 35px; */
  color: rgba(255, 255, 255);
  font-size: 12px;
  letter-spacing: 2px;
}

.jtc-coupon-desc2 {
  margin-top: 15px;
  color: #68B878;
  font-size: 12px;
  letter-spacing: 2px;
}

.jtc-coupon-1 {
  margin-top: 0.46875rem;
  color: white;
}

.jtc-coupon-2 {
  margin-top: 1rem;
  /* font-weight: 800; */
  font-size: 5.3125rem;
  color: #ec672e;
  font-family: "Bahnschrift";
}

.jtc-coupon-2 img {
  width: 15.5rem;
}

.jtc-coupon-2-1 {
  display: inline-block;
  font-size: 1.5625rem;
  margin-left: -1.25rem;
}

.jtc-input-content {
  margin-top: 1.625rem;
  display: flex;
  justify-content: center;
  width: 23.4375rem;
  align-items: center;
}

.jtc-input {
  border-width: 0px;
  background-color: #ffffff;
  border-radius: 0.3125rem;
  /* position: absolute; */
  /* bottom: 0.21875rem; */
  margin-left: 0.34375rem;
  width: 17.1875rem;
  height: 2.8125rem;
  text-align: center;
  font-size: 0.9375rem;
  color: #666;
  font-weight: normal;
}

.jtc-history {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: white;
  text-align: center;
  margin-top: 0.625rem;
  /* letter-spacing: 0.18750rem; */
}

.jtc-history-1 {}

.jtc-history-2 {
  margin-left: 1.25rem;
}

.jtc-input-placeholder {
  color: #a2a2a2;
}

/* 定义动画 */
@keyframes zoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.jtc-button {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 8rem;
  /*height: 2.5rem; */
  /* border-radius: 0.625rem; */
  /* line-height: 2.5rem; */
  /* color: white; */
  /* text-align: center; */
  /* background-color: #ec6930; */
  /* background-image: url('http://img.zhsyh.cn/donglian/btn.png'); */
  /* background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */

  /* 添加动画 */
  animation: zoom 0.6s ease-in-out alternate infinite;
}

.jtc-button img {
  width: 8rem;
}

.jtc-checkbox {
  color: #fff;
  font-size: 0.78125rem;
  text-align: center;
}

.jtc-checkbox-guize {
  display: inline;
  font-size: 0.78125rem;
  color: #ffe8bf;
  margin-bottom: 0.125rem;
}

checkbox .wx-checkbox-input {
  width: 1.25rem;
  height: 1.25rem;
  border: 0;
  border-radius: 0.3125rem;
}

.jtc-bottom {
  text-align: center;
  margin-top: 0.625rem;
  /* margin-bottom: 1.87500rem; */
  display: flex;
  width: 90vw;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 3px;
}

.jtc-jifen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jtc-jifen-info {
  /* background-color: rgba(255, 255, 255, 0.3); */
  padding: 0 0.625rem 0 0.625rem;
  border-radius: 0.625rem;
  color: #fff;
  font-size: 0.78125rem;
  margin-top: 1.4375rem;
}

.jtc-jifen-info-2 {
  color: #333;
  font-size: 0.875rem;
  margin-left: 0.125rem;
}

.jtc-jifen-info-3 {
  margin-left: 0.625rem;
}

.jtc-jifen-info-4 {}

.jtc-jifen-bg {
  /* background-image: url("http://img.zhsyh.cn/donglian/jtc/hbbg.png"); */
  background: linear-gradient(to bottom, #7CDC97, #69BA7A);
  border-radius: 15px;
  width: 19.78125rem;

  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 0.75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@font-face {
  font-family: "Bahnschrift";
  src: url("https://img.zhsyh.cn/donglian/bahnschrift.ttf");
}

.jtc-jifen-title {
  margin-top: 1.5625rem;
  font-size: 0.875rem;
  color: #68B878;
}

.jtc-jifen-price {
  display: flex;
  align-items: center;
  color: #68B878;
  font-family: "Bahnschrift";
  font-size: 4.0625rem;
  margin-top: 0.625rem;
}

.jtc-jifen-price-yuan {
  font-size: 1.5625rem;
  margin-left: 0.15625rem;
}

.jtc-jifen-score {
  color: #2b2b2b;
  font-size: 0.6875rem;
}

.jtc-jifen-btn {
  position: absolute;
  bottom: 0.9375rem;
  left: 2.5rem;
  height: 3.03125rem;

  width: 11.71875rem;
  background-image: url("http://img.zhsyh.cn/donglian/jtc/qrdh.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.jtc-tishi {
  position: absolute;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  /* margin-left: 3.28125rem; */
  margin-top: 3.4375rem;
  font-size: 0.8125rem;
  color: white;
  width: 80vw;
}

.jtc-tishi-1 {
  font-size: 0.9375rem;
  color: #f5ddba;
}

.jtc-tishi-2 {}

.jtc-tishi-3 {}

.jtc-tishi-4 {}

.jtc-sms {
  margin-top: 10px;
}

.jtc-jifen-history {
  display: flex;
  margin-top: 1rem;
  font-size: 0.9375rem;
  color: #d04d39;
}

.jtc-jifen-history-1 {
  color: #fff;
}

.jtc-jifen-history-2 {
  margin-left: 1.5625rem;
  color: #fff;
}

.qparking-jifen-top {
  color: white;
  font-family: 'Bahnschrift';
  font-size: 0.93750rem;
  margin-top: 1.25000rem;
  margin-bottom: 0.62500rem;
}

.qparking-jifen-top-price {
  font-size: 1.40625rem;
}


.qparking-customs {
  margin-bottom: 1.25000rem;
  overflow-y: scroll;
  max-height: 15rem;
}

.qparking-custom {
  margin-top: 0.15625rem;
  position: relative;
  background-image: url("http://img.zhsyh.cn/donglian/qparking/index/custombg.png");
  width: 17.46875rem;
  height: 3.40625rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.qparking-custom-left {
  color: #67B777;
  font-family: 'Bahnschrift';
  font-size: 2.18750rem;
  text-align: center;
  width: 5.68750rem;
}

.qparking-custom-left-2 {
  font-size: 0.93750rem;
  font-weight: bold;
}

.qparking-custom-right {
  display: flex;
  align-items: center;


}

.qparking-custom-right-text {
  font-family: 'Bahnschrift';
  font-size: 0.93750rem;
  font-weight: bold;
  color: #67B777;
  margin-left: 0.93750rem;

}

.qparking-custom-minus {
  margin-left: 1.25000rem;
  width: 0.93750rem;
  height: 0.93750rem;
  display: inline-block;
  background-image: url("http://img.zhsyh.cn/donglian/qparking/index/minus.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.qparking-custom-plus {
  width: 0.93750rem;
  height: 0.93750rem;
  display: inline-block;
  background-image: url("http://img.zhsyh.cn/donglian/qparking/index/plus.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.qparking-custom-right-num {
  font-size: 0.93750rem;
  width: 1.56250rem;
  text-align: center;
}

.qparking-jifen-title {
  margin-top: 0.93750rem;
  font-size: 0.77500rem;
  color: #fff;
  font-family: 'Bahnschrift';
}

.qparking-jifen-title-num {
  font-size: 1rem;
  margin: 0px 4px;
}

.qparking-jifen-btn {
  margin-top: 0.93750rem;
  height: 2.62500rem;
  width: 10.00000rem;
  background: linear-gradient(to right, #E2AF55, #D55135);
  text-align: center;
  line-height: 2.62500rem;
  color: white;
  font-size: 1.25000rem;
  border-radius: 1.31250rem;
  border-width: 0.09375rem;
  border-style: solid;
  border-color: linear-gradient(to right, #ffffff, #F5DCAA);
  box-shadow: 0px 0.18750rem 0px 0px #E0AA4F;
}

.qparking-jifen-btn-dis {
  background: linear-gradient(to right, #aaa, #aaa) !important;
  box-shadow: 0px 0.18750rem 0px 0px #aaa !important;
}

.jtc-bottom-agree {
  text-align: left;
  color: #fff;
}
</style>