import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import uuid from '../utils/Uuid'
import hhstore from '../utils/HHStore'
import config from '../utils/config'
import help from '../utils/Help'

Vue.use(Vuex)

class NetworkError extends Error {
	constructor(code, message) {
		super(message); // (1)
		this.code = code; // (2)
	}
}

export async function postf(options) {
	// 塞入did
	var did = hhstore.get("__did");
	if (!did) {
		did = uuid.uuid();
		hhstore.set("__did", did)
	}

	// 塞入uid
	var uid = hhstore.get("uid");
	if (uid) options.data.uid = uid;

	// 塞入openid
	var openid = hhstore.get("openid");
	if (openid) options.data.openid = openid;

	// 塞入渠道id
	var ch = hhstore.get("ch");
	if (ch) options.data.ch = ch;

	// 塞入是否在微信内的参数
	const UA = navigator.userAgent.toLowerCase();
	options.data.in_wechat = !!UA.match(/.*?(micromessenger\/([0-9.]+))\s*/);


	let params = help.sign(options.data, config.api_appid, config.api_key)

	var url = config.api + options.url;
	let ret;
	try {
		ret = await axios({
			method: 'POST',
			data: params,
			url: url,
			headers: {
				'content-type': 'application/json'
			},
		})
	} catch (e) {
		throw new NetworkError(999, e.name + "：" + e.message)
	}

	if (ret.data.rc == 1) {
		return ret.data.data
	} else {
		throw new NetworkError(ret.data.rc, ret.data.rc + ":" + ret.data.error);
	}
}

const store = new Vuex.Store({
	state: {
		perUrl: process.env.VUE_APP_perUrl,
		lat: '',
		lng: '',
		city: '',//当前城市
	},
	mutations: {
		SET_LAT(state, lat) {
			state.lat = lat
		},
		SET_LNG(state, lng) {
			state.lng = lng
		},
		SET_CITY(state, city) {
			state.city = city
		},
	},
	actions: {
		post({
			state
		}, options) {
			postf(options).then(function (data) {
				options.success && options.success(data)
			}).catch(function (e) {
				options.error && options.error(e.code, e.message)
			})
		},

		async syncpost({
			state
		}, options) {
			try {
				return await postf(options)
			} catch (e) {
				throw e
			}
		},
	}
})
export default store
