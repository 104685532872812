<template>
  <div class="list">
    <!-- 权益王节点 -->
    <qyw v-if="ch == 'qyw' || ch == '206497'" :goods="goods" :jifen="jifen" @historyClick="handHistory" @query="loginsms"
      @switchclicked="handChangePhone" @resend="handleResend2" @tapok="handletapok2"></qyw>

    <!-- 融信节点 -->
    <rongxin v-if="ch == 'rongxin'" :allgoods="allgoods" :goods="goods" :jifen="jifen" @historyClick="handHistory"
      @query="loginsms" @switchclicked="handChangePhone" @duihuanclicked="placeOrder"></rongxin>

    <!-- jtc节点 -->
    <jtc v-if="ch == 'jtc'" :goods="goods" :jifen="jifen" @historyClick="handHistory" @query="loginsms"
      @switchclicked="handChangePhone"></jtc>

    <!-- 卓越绿园节点 -->
    <zyly v-if="ch == 'zyly'" :allgoods="allgoods" :goods="goods" :jifen="jifen" @historyClick="handHistory"
      @query="loginsms" @switchclicked="handChangePhone" @duihuanclicked="placeOrder"></zyly>

    <!-- 千聊 -->
    <qianliao v-if="ch == 'qianliao'" :allgoods="allgoods" :goods="goods" :jifen="jifen" @historyClick="handHistory"
      @query="loginsms" @switchclicked="handChangePhone" @duihuanclicked="placeOrder"></qianliao>

    <!-- 海石 -->
    <haishi v-if="ch == 'haishi' || ch == 'jnhl' || ch == 'hualian' || ch == 'ylw'" :allgoods="allgoods" :goods="goods"
      :jifen="jifen" @historyClick="handHistory" @query="loginsms" @switchclicked="handChangePhone"
      @duihuanclicked="placeOrder">
    </haishi>

    <!-- demo -->
    <demo v-if="ch == 'demo'" :allgoods="allgoods" :goods="goods" :jifen="jifen" @historyClick="handHistory"
      @query="loginsms" @switchclicked="handChangePhone" @duihuanclicked="placeOrder"></demo>

    <!-- 兑换记录组件 -->
    <orders :visible.sync="isHistoryShown"></orders>

    <!-- 确认兑换弹窗组件 -->
    <cexchange v-if="isCEShown" :dhjf="dhjf" :qtjifen="qtjifen" :dhme="dhme" @bgclicked="handCEClick" @close="handceclose"
      @confirm="handceconfirm"></cexchange>

    <!-- 验证码组件 -->
    <vcode @bgclicked="bgclicked" @resend="handleResend" @tapok="handletapok" :title="smstitle" :show="isDialogShown"
      :phone="phone">
    </vcode>

  </div>
</template>

<script>
import qyw from "../components/partner/qyw.vue";
import rongxin from "../components/partner/rongxin.vue";
import haishi from "../components/partner/haishi.vue";
import jtc from "../components/partner/jtc.vue";
import zyly from "../components/partner/zyly.vue";
import qianliao from "../components/partner/qianliao.vue";
import demo from "../components/partner/demo.vue";
import vcode from "../components/vcode.vue";
import orders from "../components/orders.vue";
import cexchange from "../components/cexchange.vue";

import { mapActions, mapState } from "vuex";
import help from "../utils/Help";

import Vue from "vue";
import { showLoading, hideLoading } from "../components/Loading/loading";
import { Toast, Dialog } from "vant";
import { getPlatform, hideMobileNumber } from "../utils/util";
import urlParams from "../utils/UrlParams";
import config from "../utils/config";
import hhstore from "../utils/HHStore.vue";

export default {
  components: { haishi, rongxin, qianliao, qyw, jtc, zyly, demo, vcode, orders, cexchange },

  data: function () {
    return {
      smstitle: '',
      phone: "",
      autorequest: false,
      isHistoryShown: false,
      isCEShown: false,
      code: "",
      goodOrderId: "",
      points: "",
      orderId: "",
      placeOrderData: null,// 重发验证码用的

      selectGoods: [], //计算后的兑换商品
      dhjf: 0, //计算后的积分
      dhme: 0, //计算后的兑换面额
      qtjifen: 0,//蜻蜓积分
      // jifen: 880, // 用来存用户剩余积分
      // goods: [{"goodsPrice":"254","qtjifen":"200","id":"12","goodsNo":"quanbao001","stock_id":"17824809","coupon_amount":"100"},{"goodsPrice":"254","qtjifen":"200","id":"12","goodsNo":"quanbao001","stock_id":"17824809","coupon_amount":"100"},{"goodsPrice":"254","qtjifen":"200","id":"12","goodsNo":"quanbao001","stock_id":"17824809","coupon_amount":"100"}],
      // allgoods: [{ "goodsPrice": "254", "qtjifen": "200", "id": "12", "goodsNo": "quanbao001", "coupon_amount": "100", "stock_id": "17824809", "num": 3 }], // 商户的所有商品列表
      jifen: 0, // 用来存用户剩余积分
      goods: [],
      allgoods: [], // 商户的所有商品列表
      isDialogShown: false,
      vcodetype: ''
    };
  },
  props: {},
  beforeMount() { },
  mounted() {
    this.initdata();
  },

  computed: {
    ...mapState([]),
    ch() {
      return hhstore.get("ch");
    },
  },
  methods: {
    ...mapActions(["post", "syncpost"]),
    initdata() {
      this.getList()
      let phone = hhstore.get("tmp_phone");
      let response = urlParams.get("response");
      if (phone) {
        hhstore.del("tmp_phone");
        if (response) {
          let count = 0;
          var handleitv = setInterval(() => {
            if (count > 39) {
              clearInterval(handleitv);
            }

            if (window._fmOpt.blackbox) {
              this.query(phone);
              clearInterval(handleitv);
            }
            count++;
          }, 250);
        }
      }
    },
    // 发送登录验证码
    async loginsms(phone) {
      help.click_log("查询积分按钮");
      this.phone = phone;
      Toast.loading({
        message: "处理中...",
        forbidClick: true,
        duration: 0,
      });

      if (!hhstore.get("uid") && !hhstore.get("openid")) {
        hhstore.set("uid", this.phone);
      }

      this.post({
        url: "/jfdh/qt_loginsms",
        data: {
          phone: this.phone,
          callback: window.location.href,
          wx_appid: config.wx_appid,
          wx_openid: hhstore.get('openid'),
        },
        success: (res) => {
          Toast.clear();
          if (res.points) {
            this.loginsuccess(res);
          } else if (res.src) {
            Toast.loading({
              message: "正在匹配可兑换渠道...",
              forbidClick: true,
              duration: 1500,
            });
            setTimeout(() => {
              window.location.href = res.src
            }, 1500);
          } else {
            this.vcodetype = 'step1'
            this.smstitle = '积分查询验证码'
            this.isDialogShown = true
          }

        },
        error: (code, err) => {
          console.info(code, err);
          Toast.fail(err);
        },
      });
    },

    // 查询用户积分
    async query() {
      Toast.loading({
        message: "处理中...",
        forbidClick: true,
        duration: 0,
      });

      this.post({
        url: "/jfdh/qt_subLogin",
        data: {
          phone: this.phone,
          code: this.smsCode,
        },
        success: (res) => {
          Toast.clear();
          // 检查积分是否足够
          this.loginsuccess(res);
        },
        error: (code, err) => {
          console.info(code, err);
          Toast.fail(err);
        },
      });
    },
    loginsuccess(res) {
      if (res.goods.length == 0) {
        Dialog.alert({
          title: "提示",
          message: `积分不足无法兑换，当前剩余移动积分为:${res.points}`,
        });

        return;
      }

      //卓越绿园是 用户可以自己选的
      if (['zyly', 'qianliao', 'haishi', 'jnhl', 'hualian', 'ylw', 'demo', 'rongxin'].indexOf(this.ch) >= 0) {
        this.jifen = Number(res.points);
        this.goods = res.goods;
      }
      else {
        this.placeOrder(res)
      }
    },
    /* 新用户授权逻辑 ，跳转到畅游小程序 */
    async gotoreg(phone) {
      try {
        var ret = await Dialog.confirm({
          title: "提示",
          message:
            "确定使用号码" + hideMobileNumber(phone) + "授权畅游和绑定移动?",
        });
      } catch (error) {
        help.click_log("授权取消按钮");
        return;
      }
      help.click_log("授权确认按钮");
      hhstore.set("tmp_phone", phone);
      try {
        var data = await this.syncpost({
          url: "/jfdh/authorize",
          data: {
            phone: phone,
            cburl: window.location.origin + window.location.pathname,
          },
        });
      } catch (error) {
        Toast.fail(e.message);
        return;
      }
      window.location.href = data;
      // console.info(data);
    },
    /* 新用户授权逻辑 ，跳转到畅游小程序 */
    async gotobind(phone) {
      try {
        var ret = await Dialog.confirm({
          title: "提示",
          message: "请您继续完成移动绑定",
        });
      } catch (error) {
        help.click_log("绑定取消按钮");
        return;
      }

      help.click_log("绑定确认按钮");
      try {
        var data = await this.syncpost({
          url: "/jfdh/blankPage",
          data: {
            phone: phone,
            cburl:
              window.location.origin +
              window.location.pathname +
              "?phone=" +
              phone,
          },
        });
      } catch (error) {
        Toast.fail(e.message);
        return;
      }
      window.location.href = data;
      // console.info(data);
    },
    /* 点击历史记录 */
    handHistory() {
      if (!hhstore.get("uid") && !hhstore.get("openid")) {
        Toast.fail("请先输入手机号查询积分");
        return;
      }
      console.info(123);
      help.click_log("兑换记录按钮");
      this.isHistoryShown = true;
    },
    handChangePhone() {
      this.jifen = null;
      this.phone = "";
      help.click_log("切换按钮");
    },
    /* 重新发送验证码的按钮事件 */
    handleResend() {
      console.info('handleResend')
      if (this.vcodetype == 'step1') {
        this.loginsms(this.phone)
      } else {
        this.placeOrder(this.placeOrderData)
      }
    },
    handleResend2() {
      this.vcodetype = 'step2'
      this.placeOrder(this.placeOrderData)
    },
    /* 点击弹窗的Ok按钮 */
    handletapok(smsCode) {
      this.isDialogShown = false
      this.smsCode = smsCode;
      if (this.vcodetype == 'step1') {
        this.query()
      } else {
        this.handceconfirm()
        // this.isCEShown = true;
      }
    },
    handletapok2(smsCode) {
      this.vcodetype = 'step2'
      this.smsCode = smsCode;
      this.handceconfirm()
      // this.isCEShown = true;
    },
    /* 发验证发短信 */
    sendsms() {
      this.post({
        url: "/jfdh/sendCmccSms",
        data: {
          phone: this.phone,
          orderId: this.orderId,
        },
        success: (res) => {

        },
        error: (code, err) => {
          console.info(code, err);
          Toast.clear();
          Toast.fail(err);
        },
      });
    },
    handHistoryClose() {
      this.isHistoryShown = false;
    },
    handCEClick() {
      this.isCEShown = false;
    },
    handceclose() {
      this.isCEShown = false;
    },
    async handceconfirm() {
      console.info(1234);
      let machinetype = await getPlatform();

      this.isCEShown = false;
      this.isDialogShown = false;

      Toast.loading({
        message: "处理中...",
        forbidClick: true,
        duration: 0,
      });

      this.post({
        url: "/jfdh/qt_subCode",
        data: {
          phone: this.phone,
          fingerprint: window.localStorage.JK_dfpInfo,
          machinetype: machinetype,
          orderId: this.orderId,
          goodOrderId: this.goodOrderId,
          smsCode: this.smsCode,
          points: this.points,
        },
        success: (res) => {
          Toast.clear();

          this.$router.push(`/detail?goodOrderId=${this.goodOrderId}`);

          this.jifen = null;
          this.selectGoods = [];
          this.dhjf = null;
          this.dhme = null;
        },
        error: (code, err) => {
          console.info(code, err);
          Toast.clear();
          Toast.fail(err);
        },
      });
    },
    // 开始下单
    placeOrder(data) {
      // debugger
      this.placeOrderData = data
      if (!hhstore.get("uid") && !hhstore.get("openid")) {
        Toast.fail("缺少uid参数");
        return;
      }

      Toast.loading({
        message: "处理中...",
        forbidClick: true,
        duration: 0,
      });

      this.post({
        url: "/jfdh/qt_ordersms",
        data: {
          phone: this.phone,
          wx_appid: config.wx_appid,
          goodsids: data.goods.map((item) => item.id),
        },
        success: (res) => {
          Toast.clear();

          if (['zyly', 'qianliao', 'haishi', 'jnhl', 'hualian', 'ylw', 'demo', 'rongxin'].indexOf(this.ch) < 0) {
            this.jifen = Number(data.points);
            this.goods = data.goods;
          } else {
            this.vcodetype = 'step2'
            this.smstitle = '确认兑换验证码'
            this.isDialogShown = true
          }
          this.qtjifen = res.qtjifen
          this.selectGoods = data.goods;
          this.dhjf = data.goods.reduce((acc, cur) => acc + parseInt(cur.goodsPrice), 0);
          this.dhme = data.goods.reduce(
            (acc, cur) => acc + parseInt(cur.coupon_amount) / 100,
            0
          );

          this.orderId = res.orderId;
          this.points = res.points;
          this.goodOrderId = res.goodOrderId;
        },
        error: (code, err) => {
          console.info(code, err);
          Toast.clear();
          Toast.fail(err);
        },
      });
    },

    getList() {
      this.post({
        url: "/jfdh/goods_list",
        data: {},
        success: (res) => {
          this.allgoods = res
        },
        error: (code, err) => {
          Toast.fail(err);
        },
      });
    },
    /* 弹窗背景点击事件 */
    bgclicked() {
      this.isDialogShown = false
    },
  },
};
</script>
<style scoped>
/* 应用于电脑的样式 */
@media screen and (min-width: 768px) {

  /* 在屏幕宽度大于等于768px时应用这些样式 */
  .list {
    max-width: 23.4375rem;
    margin: 0 auto;
  }
}

/* 应用于手机的样式 */
@media screen and (max-width: 767px) {
  .list {
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>
