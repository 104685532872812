<template>
    <div class="qyw-container">
        <div class="qyw-scroll" :style="{ 'background-image': bgimg1 }">
            <img src="http://img.zhsyh.cn/rongxin/kefu.png" class="qyw-kefu" @click="handlekefu" />
            <img src="http://img.zhsyh.cn/rongxin/jilu.png" class="qyw-history" @click="handHistory" />
            <!-- <img src="http://img.zhsyh.cn/donglian/text-top-left.png" class="qyw-top-left" /> -->


            <div v-if="jifen" class="jtc-jifen">
                <div class="jtc-jifen-info">
                    <span class="jtc-jifen-info-1">{{ phone }}</span>
                    <span class="jtc-jifen-info-2" @click="switchclicked">切换</span>
                    <span class="jtc-jifen-info-3">移动积分：</span>
                    <span class="jtc-jifen-info-4">{{ jifen }}</span>
                </div>
                <div class="jtc-jifen-bg">
                    <div class="qparking-jifen-top">您最高可兑换
                        <span class="qparking-jifen-top-price">{{ dhzd }}</span>
                        元券包
                    </div>

                    <div class="haishi-customs">
                        <div class="haishi-custom" v-for="(item, index) in selectGoods" :key="index">
                            <div class="haishi-custom-left">{{ item.coupon_amount / 100 }}<span
                                    class="haishi-custom-left-2">元</span>
                            </div>
                            <div class="haishi-custom-right">
                                <span class="haishi-custom-right-text">立减代金券</span>
                                <div class="haishi-custom-minus" @click="handleminus(item)"></div>
                                <span class="haishi-custom-right-num">{{ item.num }}</span>
                                <div class="haishi-custom-plus" @click="handleplus(item)"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="qparking-jifen-title">
                    <span v-if="dhjf > jifen">积分不足{{ dhjf }}，无法兑换</span>
                    <span v-else-if="dhzd < dhme">超出最大兑换面额</span>
                    <span v-else>您本次兑换<span class="qparking-jifen-title-num">{{ dhme }}</span>元（消耗<span
                            class="qparking-jifen-title-num">{{ dhjf }}</span>移动积分 =<span
                            class="qparking-jifen-title-num">{{ dhqt
              }}</span>蜻蜓积分）</span>
                </div>
                <div class="qparking-jifen-btn"
                    :class="{ 'qparking-jifen-btn-dis': dhzd < dhme || dhjf > jifen || dhme == 0 }"
                    @click="duihuanclicked">立即兑换</div>

                <!-- <div class="jtc-jifen-history">
          <div class="jtc-jifen-history-1" @click="handHistory">兑换记录</div>
          <div class="jtc-jifen-history-2" @click="handleguize">活动规则</div>
        </div> -->
            </div>
            <div v-if="!jifen" class="qyw-coupon-contain">
                <div class="qyw-coupon" :style="{ 'background-image': bgimg2 }">
                    <div class="qyw-coupon-1">最高可兑</div>
                    <div class="qyw-coupon-2">30</div>
                </div>
                <input placeholder-class="qyw-input-placeholder" class="qyw-input" placeholder="请输入手机号"
                    v-model="phone" />

                <div class="qyw-button" @click="query"></div>

                <div class="qyw-bottom">
                    <van-checkbox v-model="agree" checked-color="#58BE6A" shape="square"><span
                            class="jtc-bottom-agree">我已阅读并同意</span>
                        <div class="qyw-checkbox-guize" @click.stop="handlexieyi">
                            《用户协议》
                        </div>
                        <div class="qyw-checkbox-guize" @click.stop="handleguize">
                            《活动规则》
                        </div>
                    </van-checkbox>
                </div>
            </div>
            <div class="qyw-tishi" v-if="showTishi">
                <div class="qyw-tishi-1">使用提示</div>
                <div class="qyw-tishi-2">1、兑换成功后可领取立减金</div>
                <div class="qyw-tishi-3">2、立减金有效期为{{style.day}}天，可叠加使用</div>
                <div class="qyw-tishi-4">3、如有问题请联系官方客服 400-001-2575</div>
            </div>

            <!-- 协议组件 -->
            <xieyi v-if="isShowxieyi" @bgclicked="xybgclicked"></xieyi>

            <!-- 规则组件 -->
            <guize v-if="isShowguize" @bgclicked="gzbgclicked"></guize>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import {
        mapActions,
        mapState
    } from "vuex";

    import wxutils from '@/utils/Wx'
    import help from '@/utils/Help'
    import guize from "../guize.vue";
    import xieyi from "../xieyi.vue";
    import {
        Checkbox,
        CheckboxGroup
    } from "vant";
    import {
        Toast,
        Dialog
    } from "vant";
    import {
        isPhoneNumber
    } from "@/utils/util";
    import hhstore from "@/utils/HHStore.vue";
    import urlParams from "@/utils/UrlParams";
    import sms from "../sms.vue";
    import config from "@/utils/config";
    Vue.use(Checkbox);
    Vue.use(CheckboxGroup);
    export default {
        components: {
            guize,
            xieyi,
            sms
        },
        props: {
            jifen: {
                type: Number,
                default: null,
            },
            goods: {
                type: Array,
                default: [],
            },
            allgoods: { // 所有的产品
                type: Array,
                value: []
            },
        },
        watch: {
            goods(nvalue, oldValue) {
                // 属性值发生变化时执行的操作
                if (!nvalue) return


                let data = this.allgoods.map(item => {
                    item.num = 0
                    for (const item2 of this.goods) {
                        if (item.id == item2.id) {
                            item.num = item.num + 1
                        }

                    }
                    return item
                })

                this.refsgoods(data)
            }
        },
        data() {
            return {
                style: config.style(),
                selectGoods: [],
                couponStyle: "",
                phone: "",
                agree: true,
                isShowxieyi: false,
                isShowguize: false,
                bgimg1: "url('http://img.zhsyh.cn/rongxin/bg2.jpg')",
                bgimg2: "url('http://img.zhsyh.cn/rongxin/input-bg2.png')",
            };
        },

        computed: {
            dhme() {
                return this.selectGoods.reduce((acc, cur) => acc + parseInt(cur.coupon_amount) * cur.num / 100, 0)
            },
            dhjf() {
                return this.selectGoods.reduce((acc, cur) => acc + parseInt(cur.goodsPrice) * cur.num, 0);
            },
            dhqt() {
                return this.selectGoods.reduce((acc, cur) => acc + parseInt(cur.qtjifen) * cur.num, 0);
            },
            dhzd() {
                return this.goods.reduce((acc, cur) => acc + parseInt(cur.coupon_amount) / 100, 0)
            },
            showTishi() {
                return true
            },
        },
        async mounted() {
            if (hhstore.get("tmp_phone")) {
                this.phone = hhstore.get("tmp_phone");
            } else if (hhstore.get("phone")) {
                this.phone = hhstore.get("phone");
            }



            if (config.style().homebg)
                this.bgimg1 = "url('" + config.style().homebg + "')"
            // if (hhstore.get("wxlj") == '0') {
            //   hhstore.del("wxlj");
            // } else if (urlParams.get("wxlj")) {
            //   hhstore.set("wxlj", urlParams.get("wxlj"));
            //   this.bgimg1 = "url('http://img.zhsyh.cn/rongxin/bg2.jpg')"
            //   this.bgimg2 = "url('http://img.zhsyh.cn/rongxin/input-bg2.png')"
            // } else if (hhstore.get("wxlj")) {
            //   this.bgimg1 = "url('http://img.zhsyh.cn/rongxin/bg2.jpg')"
            //   this.bgimg2 = "url('http://img.zhsyh.cn/rongxin/input-bg2.png')"
            // }

            await wxutils.auth(config.wx_appid);
            // this.getsrc()
        },
        methods: {
            ...mapActions(["post", "syncpost"]),

            getsrc() {
                if (!hhstore.get('openid')) {
                    return
                }

                this.post({
                    url: "/jfdh/cmcclink",
                    data: {
                        ch: 'rongxin',
                        callback: window.location.href,
                        wx_appid: config.wx_appid,
                        wx_openid: hhstore.get('openid'),
                    },
                    success: (res) => {
                        window.location.href = res
                    },
                    error: (code, err) => {
                        console.info(code, err);
                        Toast.clear();
                        Toast.fail(err);
                    },
                });
            },
            /* 点击兑换记录 */
            handHistory() {
                this.$emit("historyClick");
            },
            handleguize() {
                this.isShowguize = true;
                help.click_log("活动规则按钮");
            },
            handlexieyi() {
                help.click_log("协议按钮");
                this.isShowxieyi = true;
            },
            handlekefu() {
                window.location.href =
                    "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97832154c3744d9bec1ce2e761e9194029dcd621e4cbceb822e6cd587a8f3ed33e78f6af9dc6c79786d362ef01848a65fd";
            },

            // 查询用户积分
            async query() {
                if (!this.agree) {
                    Toast({
                        message: "请先阅读并同意活动规则~",
                        icon: null,
                    });
                    return;
                }

                if (!isPhoneNumber(this.phone)) {
                    Toast({
                        message: "手机号填写不正确",
                        icon: null,
                    });
                    return;
                }

                hhstore.set("phone", this.phone);
                this.$emit("query", this.phone);
            },
            xybgclicked() {
                this.isShowxieyi = false;
            },
            gzbgclicked() {
                this.isShowguize = false;
            },
            switchclicked() {
                this.phone = "";
                this.$emit("switchclicked");

            },
            handleResend() {
                console.info('handleResend')
                this.$emit("resend");
            },
            handletapok(sms) {
                console.info(sms)
                this.$emit("tapok", sms);
            },
            handleplus(ci) {

                let data = this.selectGoods.map(item => {
                    if (item.id == ci.id) {
                        item.num += 1
                    }
                    return item;
                })

                this.refsgoods(data)
            },
            handleminus(ci) {

                let data = this.selectGoods.map(item => {
                    if (item.id == ci.id) {
                        item.num = Math.max(0, item.num - 1)
                    }
                    return item;
                })
                this.refsgoods(data)
            },
            refsgoods(goods) {
                this.selectGoods = goods;

            },
            duihuanclicked() {
                if (this.dhzd < this.dhme) {
                    return
                }
                //格式转换一下
                let data = []
                for (const item of this.selectGoods) {
                    for (let index = 0; index < item.num; index++) {
                        const {
                            num,
                            ...newObj
                        } = item;
                        data.push(newObj)
                    }
                }
                this.$emit('duihuanclicked', {
                    goods: data
                })
            },
        },
    }
</script>

<style scoped>
    /* 隐藏垂直滚动条 */
    div::-webkit-scrollbar {
        width: 0rem;
        /* 设置滚动条宽度为0，这将隐藏滚动条 */
    }

    /* 隐藏水平滚动条 */
    div::-webkit-scrollbar-horizontal {
        display: none;
    }

    /* 隐藏垂直滚动条轨道 */
    div::-webkit-scrollbar-track {
        display: none;
    }

    .qyw-container {
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: scroll;
    }

    .qyw-scroll {
        width: 100%;
        height: 50.25rem;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .qyw-kefu {
        position: absolute;
        width: 1.5625rem;
        height: 4rem;
        top: 25.125rem;
        right: 0rem;
    }

    .qyw-history {
        position: absolute;
        width: 1.5625rem;
        height: 5.3125rem;
        top: 18.625rem;
        right: 0rem;
    }


    .qyw-top-left {
        margin-top: 2.5rem;
        margin-left: 1.2812rem;
        width: 5.0625rem;
        height: 0.9375rem;
    }

    .qyw-top {
        margin-top: calc(3vh);
        margin-left: 3.5rem;
        width: 18.7812rem;
        height: 8.8125rem;
    }

    .qyw-coupon-contain {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .qyw-coupon {
        display: flex;

        justify-content: center;
        align-items: flex-start;
        margin-top: 33vh;
        /* margin-left: 3.7438rem; */
        width: 16.1875rem;
        height: 12.9375rem;
        position: relative;

        background-size: cover;
        background-repeat: no-repeat;
    }

    .qyw-coupon-1 {
        color: #CB5E2E;
    }

    .qyw-coupon-2 {
        position: absolute;
        top: 5.25rem;
        left: 3.6875rem;
        font-size: 4.375rem;
        color: #E85345;
        font-family: "Bahnschrift";

    }


    .qyw-input {
        background-color: #ffffff;
        /* border-radius: 0rem 0rem 0.625rem 0.625rem; */
        /* position: absolute; */
        margin-bottom: 0.2188rem;
        margin-left: 1px;
        margin-top: 10px;
        /* margin-left: 0.3438rem; */
        width: 17.2rem;
        padding: 0px;
        border-width: 0px;
        height: 2.8125rem;
        text-align: center;
        font-size: 1rem;
        color: #666;
        font-weight: normal;
    }


    .qyw-history-1 {}

    .qyw-history-2 {
        margin-left: 1.25rem;
    }

    .qyw-input-placeholder {
        color: #a2a2a2;
    }

    /* 定义动画 */
    @keyframes zoom {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(1.1);
        }
    }

    .qyw-button {
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 13.8125rem;
        height: 2.5rem;

        background-color: rgba(0, 0, 0, 0);
        background-image: url("http://img.zhsyh.cn/donglian/btn.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        /* 添加动画 */
        animation: zoom 0.6s ease-in-out alternate infinite;
    }

    .qyw-checkbox {
        color: #fff;
        font-size: 0.7812rem;
        text-align: center;
    }

    .qyw-checkbox-guize {
        display: inline;
        font-size: 0.7812rem;
        color: #ffe8bf;
        margin-bottom: 0.125rem;
    }

    checkbox .wx-checkbox-input {
        width: 1.25rem;
        height: 1.25rem;
        border: 0;
        border-radius: 0.3125rem;
    }

    .qyw-bottom {
        text-align: center;
        margin-top: 1.875rem;
        /* margin-bottom: 1.8750rem; */
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .qyw-jifen {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .qyw-jifen-info {
        color: #fff;
        font-size: 0.7812rem;
        position: absolute;
        top: 29px;
        /* margin-top: 1.5938rem; */
    }

    .qyw-jifen-info-2 {
        color: #d04d39;
        font-size: 0.875rem;
        margin-left: 0.125rem;
    }

    .qyw-jifen-info-3 {
        margin-left: 0.625rem;
    }

    .qyw-jifen-info-4 {}

    .qyw-jifen-bg {

        width: 16.1875rem;
        height: 12.9375rem;
        position: relative;

        background-size: cover;
        background-repeat: no-repeat;
        justify-content: space-between;
        margin-top: 15rem;
        /* border-color: #f8ddb7;
  border-style: solid;
  border-width: 0.1562rem;
  border-radius: 0.625rem; */

        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    @font-face {
        font-family: "Bahnschrift";
        src: url("https://img.zhsyh.cn/donglian/bahnschrift.ttf");
    }

    .qyw-jifen-title {
        /* margin-top: 1.4375rem; */
        font-size: 1rem;
        color: #d04d39;
    }

    .qyw-jifen-price {

        position: absolute;
        top: 5.25rem;
        left: 3.6875rem;
        font-size: 4.375rem;
        color: #E85345;
        font-family: "Bahnschrift";

        /* margin-top: 0.625rem; */
    }

    .qyw-jifen-price-yuan {
        font-size: 1.5625rem;
        margin-left: 0.1562rem;
    }

    .qyw-jifen-score {
        color: #fff;
        font-size: 0.6875rem;
        margin-bottom: 19px;
    }

    .qyw-jifen-btn {
        position: absolute;
        bottom: 0rem;

        left: 0rem;
        height: 2.4375rem;
        background-color: #d04d39;
        width: 100%;
        text-align: center;
        line-height: 2.4375rem;
        color: white;
        font-size: 1rem;
        border-radius: 0rem 0rem 0.625rem 0.625rem;
    }

    .qyw-tishi {
        display: flex;
        flex-direction: column;
        margin-left: 3.2812rem;
        margin-top: 3.4375rem;
        font-size: 0.8125rem;
        color: white;
    }

    .qyw-tishi-1 {
        font-size: 0.9375rem;
        color: #f5ddba;
    }

    .qyw-tishi-2 {}

    .qyw-tishi-3 {}

    .qyw-tishi-4 {}

    .qyw-jifen-history {
        display: flex;
        margin-top: 0.5625rem;
        font-size: 0.9375rem;
        color: #d04d39;
    }

    .qyw-jifen-history-1 {}

    .qyw-jifen-history-2 {
        margin-left: 1.5625rem;
    }
</style>
<style>
    .van-checkbox__label {
        color: white;
        font-size: 0.7812rem;
    }

    .van-checkbox__icon {
        background-color: white;
    }









    .jtc-jifen {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .jtc-jifen-info {
        padding: 0 0.625rem 3px 0.625rem;
        border-radius: 0.625rem;
        color: #fff;
        font-size: 0.78125rem;
        margin-top: 33vh;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .jtc-jifen-info-2 {
        color: #fff;
        font-size: 0.875rem;
        margin-left: 0.125rem;
    }

    .jtc-jifen-info-3 {
        margin-left: 0.625rem;
    }

    .jtc-jifen-info-4 {}

    .jtc-jifen-bg {
        /* background-image: url("http://img.zhsyh.cn/donglian/jtc/hbbg.png"); */
        background: linear-gradient(to bottom, #7CDC97, #69BA7A);
        border-radius: 15px;
        width: 19.78125rem;

        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 0.75rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }


    .jtc-jifen-title {
        margin-top: 1.5625rem;
        font-size: 0.875rem;
        color: #68B878;
    }

    .jtc-jifen-price {
        display: flex;
        align-items: center;
        color: #68B878;
        font-family: "Bahnschrift";
        font-size: 4.0625rem;
        margin-top: 0.625rem;
    }

    .jtc-jifen-price-yuan {
        font-size: 1.5625rem;
        margin-left: 0.15625rem;
    }

    .jtc-jifen-score {
        color: #2b2b2b;
        font-size: 0.6875rem;
    }

    .jtc-jifen-btn {
        position: absolute;
        bottom: 0.9375rem;
        left: 2.5rem;
        height: 3.03125rem;

        width: 11.71875rem;
        background-image: url("http://img.zhsyh.cn/donglian/jtc/qrdh.png");
        background-size: cover;
        background-repeat: no-repeat;
    }


    .qparking-jifen-top {
        color: white;
        font-family: 'Bahnschrift';
        font-size: 0.93750rem;
        margin-top: 1.25000rem;
        margin-bottom: 0.62500rem;
    }

    .qparking-jifen-top-price {
        font-size: 1.40625rem;
    }



    .haishi-customs {
        margin-bottom: 1.25000rem;
        overflow-y: scroll;
        max-height: 15rem;
    }

    .haishi-custom {
        margin-top: 0.15625rem;
        position: relative;
        background-image: url("http://img.zhsyh.cn/donglian/qparking/index/custombg.png");
        width: 17.46875rem;
        height: 3.40625rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
    }

    .haishi-custom-left {
        color: #67B777;
        font-family: 'Bahnschrift';
        font-size: 2.18750rem;
        text-align: center;
        width: 5.68750rem;
    }

    .haishi-custom-left-2 {
        font-size: 0.93750rem;
        font-weight: bold;
    }

    .haishi-custom-right {
        display: flex;
        align-items: center;


    }

    .haishi-custom-right-text {
        font-family: 'Bahnschrift';
        font-size: 0.93750rem;
        font-weight: bold;
        color: #67B777;
        margin-left: 0.93750rem;

    }

    .haishi-custom-minus {
        margin-left: 1.25000rem;
        width: 0.93750rem;
        height: 0.93750rem;
        display: inline-block;
        background-image: url("http://img.zhsyh.cn/donglian/qparking/index/minus.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .haishi-custom-plus {
        width: 0.93750rem;
        height: 0.93750rem;
        display: inline-block;
        background-image: url("http://img.zhsyh.cn/donglian/qparking/index/plus.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .haishi-custom-right-num {
        font-size: 0.93750rem;
        width: 1.56250rem;
        text-align: center;
    }


    .qparking-jifen-title {
        margin-top: 0.93750rem;
        font-size: 0.77500rem;
        color: #fff;
        font-family: 'Bahnschrift';
    }

    .qparking-jifen-title-num {
        font-size: 1rem;
        margin: 0px 4px;
    }

    .qparking-jifen-btn {
        margin-top: 0.93750rem;
        height: 2.62500rem;
        width: 10.00000rem;
        background: linear-gradient(to right, #E2AF55, #D55135);
        text-align: center;
        line-height: 2.62500rem;
        color: white;
        font-size: 1.25000rem;
        border-radius: 1.31250rem;
        border-width: 0.09375rem;
        border-style: solid;
        border-color: linear-gradient(to right, #ffffff, #F5DCAA);
        box-shadow: 0px 0.18750rem 0px 0px #E0AA4F;
    }

    .qparking-jifen-btn-dis {
        background: linear-gradient(to right, #aaa, #aaa) !important;
        box-shadow: 0px 0.18750rem 0px 0px #aaa !important;
    }

    .jtc-bottom-agree {
        text-align: left;
        color: #fff;
    }
</style>