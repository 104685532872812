<template>
  <confirm @confirm="handceconfirm" @close="handceclose">
    <div class="ce-container" :style="{ '--p1color--': style.p1color }">
      <div class="ce-title"></div>
      <img :src="style.img1" class="ce-img" />
      <div class="ce-desc">
        <span>{{ dhjf }}</span>
        <span>{{ qtjifen }}</span>
        <span>{{ dhme }}元</span>
      </div>
      <div class="ce-text">此兑换将消耗{{ dhjf }}移动积分</div>
    </div>
  </confirm>
</template>

<script>
import Vue from "vue";
import help from "../utils/Help";

import config from "../utils/config";
import { Toast, Dialog } from "vant";
import { Popup } from "vant";

import confirm from "./confirm";
import countdown from "./countdown";

Vue.use(Popup);
export default {
  components: { countdown, confirm },
  props: {
    dhjf: {
      type: Number,
      default: "",
    },
    qtjifen: {
      type: Number,
      default: "",
    },
    dhme: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      style: config.style(),
    };
  },
  computed: {},
  methods: {
    handceconfirm() {
      this.$emit("confirm");
      help.click_log("确认框确认按钮");

    },
    handceclose() {
      this.$emit("close");
      help.click_log("确认框取消按钮");

    },
  },
};
</script>

<style scoped>
.ce-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.25rem;
  margin-top: 0rem;
}

.ce-title {
  text-align: center;
  background-color: var(--p1color--);
  color: white;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1.125rem;
}

.ce-img {
  margin-top: 0.625rem;
  width: 17rem;
  height: 3.75rem;
}

.ce-text {
  margin-top: 1.25rem;
  color: #d84836;
  text-align: center;
}

.ce-desc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 4.37500rem; */

  /* width: 90%; */
  margin-right: 2.5rem;
  margin-left: 0.4375rem;
}
</style>