<template>
  <div :style="{ '--p1color--': style.p1color }">
    <div class="sms-container">
      <div class="sms-yzm">
        <input
          placeholder-class="sms-input-placeholder"
          type="number"
          maxlength="6"
          placeholder="请输入6位数验证码"
          v-model="sms"
          class="sms-input"
        />
        <countdownbtn
          @resend="handleResend" 
          v-if="show"
          class="sms-daojishi"
          countdown="60"
        ></countdownbtn>
      </div>
    </div>
    <div class="sms-button" @click="handletapok">确认兑换</div>
  </div>
</template>

<script>
import Vue from "vue";
import help from "../utils/Help";

import config from "../utils/config";
import { Toast, Dialog } from "vant";
import { Popup } from "vant";

import countdownbtn from "./countdownbtn";

Vue.use(Popup);
export default {
  components: { countdownbtn },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    phone: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sms: "",
      style: config.style(),
    };
  },
  computed: {},
  methods: {
    bgclicked() {
      // this.triggerEvent('bgclicked', 1)
    },
    /* 输入验证码的事件 */

    handleResend() {
      this.$emit("resend");
    },
    handlecancel() {
      this.$emit("bgclicked", 1);
    },
    handletapok() {
      const reg = /^\d{6}$/;
      if (!reg.test(this.sms)) {
        Toast({
          message: "验证码格式不正确",
          icon: null,
        });

        return;
      }
      help.click_log("确认兑换按钮");
      this.$emit("tapok", this.sms);
    },
  },
};
</script>

<style scoped>
.sms-container {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  /* padding: 20px; */
  /* width: 70vw; */
}

.sms-title {
  font-size: 1.28125rem;
  color: #333;
}

.sms-text {
  margin-top: 0.75rem;
  font-size: 0.9375rem;
  color: #979797;
}

.sms-tishi {
  margin-top: 0.25rem;
  font-size: 1.8125rem;
  color: #333;
}

.sms-yzm {
  display: flex;

  margin-top: 0.625rem;
  /* border-bottom: solid;
  border-width: 0.03125rem;
  border-color: #888; */
  padding: 0.3125rem;
  color: #333;
}

.sms-input-placeholder {
  color: #EEF1F4;
}
input::placeholder {
  color: #B7C2C7; /* 设置占位符文本的颜色 */
}
.sms-input{
  border-width: 0px;
  background-color: #fff;
  border-radius: 0.3125rem;
  /* position: absolute; */
  /* bottom: 0.21875rem; */
  margin-right: 0.5rem;
  width: 13rem;
  height: 2.8125rem;
  text-align: center;
  font-size: 0.9375rem;
  color: #666;
  font-weight: normal;
}

.sms-buttons {
  margin-top: 1.5625rem;
  display: flex;
  gap: 0.625rem;
  justify-content: center;
}

.sms-button{
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 16.2rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  line-height: 2.5rem;
  color: white;
  text-align: center;
  background-color: var(--p1color--);
  /* background-image: url('http://img.zhsyh.cn/donglian/btn.png'); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 15px;
}
/* .sms-daojishi {
  margin-top: 0.75rem;
} */

.sms-ok {
  height: 2.125rem;
  width: 6.875rem;
  text-align: center;
  background-color: var(--p1color--);
  color: white;
  border-radius: 1.0625rem;
  line-height: 2.125rem;
}

.sms-cancel {
  line-height: 2.125rem;
  height: 2.125rem;
  width: 6.875rem;
  text-align: center;
  background-color: #d3d3d3;
  color: white;
  border-radius: 1.0625rem;
}
</style>