<script>
	import CryptoJs from 'crypto-js'
	var md5 = require('md5')
	export default {
		/**
		 * 加密
		 */
		encrypt(word, k, sec) {
			let key = CryptoJs.enc.Utf8.parse(md5(k).substr(0, 16));
			let iv = CryptoJs.enc.Utf8.parse(sec.substr(0, 16));
			let srcs = CryptoJs.enc.Utf8.parse(word);
			let encrypted = CryptoJs.AES.encrypt(srcs, key, {
				mode: CryptoJs.mode.CBC,
				padding: CryptoJs.pad.Pkcs7,
				iv: iv,
			});
			return encrypted.toString();
		},

		/**
		 * 解密
		 */
		decrypt(word, k, sec) {
			let key = CryptoJs.enc.Utf8.parse(md5(k).substr(0, 16));
			let iv = CryptoJs.enc.Utf8.parse(sec.substr(0, 16));
			let decrypt = CryptoJs.AES.decrypt(word, key, {
				mode: CryptoJs.mode.CBC,
				padding: CryptoJs.pad.Pkcs7,
				iv: iv,
			});
			return CryptoJs.enc.Utf8.stringify(decrypt).toString();
		},
		//解密方法,小程序过来的
		decrypt_mini(word, k, iv) {
			const kk = CryptoJs.enc.Utf8.parse(k); //十六位十六进制数作为密钥
			const iiv = CryptoJs.enc.Utf8.parse(iv); //十六位十六进制数作为密钥偏移量
			let encryptedHexStr = CryptoJs.enc.Hex.parse(word);
			let srcs = CryptoJs.enc.Base64.stringify(encryptedHexStr);
			let decrypt = CryptoJs.AES.decrypt(srcs, kk, {
				iv: iiv,
				mode: CryptoJs.mode.CBC,
				padding: CryptoJs.pad.Pkcs7
			});
			let decryptedStr = decrypt.toString(CryptoJs.enc.Utf8);
			return decryptedStr.toString();
		}
	}
</script>
