<template>
  <!--components/guize/guize.wxml-->
  <van-popup v-model="show" round @close="handclose">
    <div class="guize-container">
      <div class="guize-close" @click="handclose">
        <img src="//img.zhsyh.cn/donglian/close.png"></img>
      </div>
      <div class="guize-title">活动规则</div>
      <div class="guize-content">
        <pre class="guize-pre">
1、本活动仅限中国移动用户参与;
2、积分一经兑换不支持退回，不支持开发票，立减券兑换后{{
  style.day
}}天内有效，请及时使用，逾期作废; 
3、立减券查询路径：{{
  style.lujing
}}; 
4、客服热线：4000012575;
{{ ch == 'zyly' ? '5、点击"立即兑换"，系统将自动为您注册绿园账号，欢迎登录使用卓越绿园APP进行充电，购物！' : '' }}

        </pre>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from "vue";

import config from "../utils/config";
import { Toast, Dialog } from "vant";
import { Popup } from "vant";
import hhstore from "@/utils/HHStore";

Vue.use(Popup);

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.ch = hhstore.get('ch')
  },
  data() {
    return {
      show: true,
      ch: "",
      style: config.style(),
    };
  },
  methods: {
    bgclicked() {
      // this.triggerEvent('bgclicked', 1)
    },

    handclose() {
      this.$emit("bgclicked", 1);
    },
  },
};
</script>

<style scoped>
/* components/guize/guize.wxss */
.guize-container {
  padding: 20px;
  width: 70vw;
  max-width: 16rem;
  position: relative;
}

.guize-title {
  text-align: center;
  font-size: 1.25000rem;
}

.guize-content {
  /* margin-top: 1.25000rem; */
  font-size: 0.87500rem;
  height: 23vh;
  overflow-y: scroll;
  width: 100%;
}

.guize-close {
  position: absolute;
  right: 1.00000rem;
  top: 1.00000rem;
}

.guize-close img {
  width: 1.15625rem;
  height: 1.00000rem;
}

.guize-pre {
  white-space: pre-wrap;
  width: 100%;
}
</style>
