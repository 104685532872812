<template>
  <van-popup v-model="show" @close="handclose" round>
    <div class="cc-container" :style="{ '--p1color--': style.p1color }">
      <div class="cc-content">
        <slot></slot>
      </div>
      <div class="cc-buttons">
        <div class="cc-buttons-1" @click="handclose">{{ canceltext }}</div>
        <div class="cc-buttons-2" @click="handconfirm">{{ oktext }}</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Toast, Dialog } from "vant";

import config from "../utils/config";

export default {
  props: {
    oktext: {
      type: String,
      default: "确定",
    },
    canceltext: {
      type: String,
      default: "取消",
    },
  },

  data() {
    return {
      style: config.style(),
      show: true,
    };
  },

  computed: {},
  methods: {
    bgclicked() {},
    handconfirm() {
      console.info(123);
      this.$emit("confirm");
    },
    handclose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
/* components/confirm/confirmexchange.wxss */
.cc-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.cc-buttons {
  width: 17.6875rem;
  display: flex;
  justify-content: center;
}

.cc-buttons-1 {
  background-color: #d3d3d3;
  text-align: center;
  line-height: 2.125rem;
  text-align: center;
  width: 6.8125rem;
  height: 2.125rem;
  border-radius: 1.0625rem;
  color: white;
}

.cc-buttons-2 {
  border-radius: 1.0625rem;
  background-color: var(--p1color--);
  color: white;
  margin-left: 1.25rem;
  text-align: center;
  line-height: 2.125rem;
  width: 6.8125rem;
  height: 2.125rem;
}
</style>