<template>
  <div>
    
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import help from "../utils/Help";
import config from "../utils/config";
import Vue from "vue";
import { showLoading, hideLoading } from "../components/Loading/loading";
import { Toast, Dialog } from "vant";
import { getPlatform, hideMobileNumber } from "../utils/util";
import urlParams from "../utils/UrlParams";
import hhstore from "../utils/HHStore.vue";
export default {
  components: {},
  data: function () {
    return {
    
    };
  },
  beforeMount() {},
  mounted() {
    
  },
  computed: {
    ...mapState([]),
  },
  methods: {
    ...mapActions(["post", "syncpost"]),

  },
};
</script>
<style scoped>

</style>
