<template>
  <div>
    <div
      v-if="coupons"
      class="detail-contain"
      :style="{ '--p1color--': style.p1color }"
    >
      <img
        src="//img.zhsyh.cn/donglian/kefu3.png"
        class="detail-kefu"
        @click="handlekefu"
      />
      <img
        @click="handleback"
        src="//img.zhsyh.cn/donglian/return.png"
        class="detail-return"
      />
      <img
        src="//img.zhsyh.cn/donglian/success.png"
        class="detail-success"
      />

      <div v-if="status == '0' && received == 1"></div>
      <div v-else>
        <div
          style="
            width: 10.875rem;
            height: 2.4375rem;
            line-height: 2.4375rem;
            background-color: #c0c0c0;
            color: white;
            font-size: 1.25rem;
            border-radius: 1.21875rem;
            text-align: center;
            margin-top: 1.875rem;
          "
        >
          {{ getStatus(status, received) }}
        </div>
      </div>

      <div class="detail-coupons">
        <div
          class="detail-coupons-item"
          v-for="(coupon, index) in coupons"
          :key="index"
        >
          <div class="detail-coupons-item-left" v-if="coupon.logo">
            <img :src="coupon.logo" />
          </div>
          <div class="detail-coupons-item-right">
            <div class="detail-coupons-item-right-top">
              <div class="detail-coupons-item-right-top-left">
                {{ coupon.coupon_name }}
              </div>
              <div class="detail-coupons-item-right-top-right">
                {{ couponStatus(coupon.code, coupon.status) }}
              </div>
            </div>
            <div class="detail-coupons-item-right-bottom">
              <div
                class="detail-coupons-item-right-bottom-1"
                v-if="coupon.use_condition"
              >
                满{{ coupon.use_condition }}可用 |
              </div>
              有效期至 {{ timestampToDate(coupon.end_time) }}
            </div>
          </div>
        </div>
      </div>

      <div class="detail-bottom">
        <div class="detail-bottom-1">{{ style.shuoming }}</div>
        <div class="detail-bottom-2">如优惠券领取失败，请联系客服！</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import help from "../utils/Help";
import config from "../utils/config";
import Vue from "vue";
import { showLoading, hideLoading } from "../components/Loading/loading";
import { Toast, Dialog } from "vant";
import { getPlatform, hideMobileNumber } from "../utils/util";
import urlParams from "../utils/UrlParams";
import hhstore from "../utils/HHStore.vue";
export default {
  components: {},
  data: function () {
    return {
      coupon: {},
      goodOrderId: null,
      coupons: null,
      received: null,
      status: null,
      style: config.style(),
    };
  },
  beforeMount() {},
  mounted() {
    this.goodOrderId = urlParams.get("goodOrderId");
    this.getOrder();
  },
  computed: {
    ...mapState([]),
  },
  methods: {
    ...mapActions(["post", "syncpost"]),

    timestampToDate(timestamp) {
      // 将秒转换为毫秒，并加上时区偏移量（8 小时的毫秒数）
      var date = new Date(timestamp * 1000 + 8 * 60 * 60 * 1000);

      // 获取年份、月份和日期
      var year = date.getUTCFullYear();
      var month = date.getUTCMonth() + 1; // 月份是从 0 开始的，所以需要加 1
      var day = date.getUTCDate();

      // 格式化日期字符串
      return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
    },

    getStatus(status, received) {
      console.info("getStatus");
      switch (status) {
        // 0付款成功，1部分退款，2全部退款，3退款失败，4未付款，5订单异常
        case "0": {
          if (received == 1) {
            return "未领取";
          } else {
            return "已领取";
          }
        }
        case "1":
          return "部分退款";
        case "2":
          return "全部退款";
        case "3":
          return "退款失败";
        case "4":
          return "未付款";
        case "5":
          return "订单异常";
        default:
          return "未知状态";
      }
    },

    couponStatus(code, status) {
      switch (code) {
        // 0付款成功，1部分退款，2全部退款，3退款失败，4未付款，5订单异常
        case "DUPREQUEST":
        case "SUCCESS": {
          // 代金券状态：SENDED：可用USED：已实扣EXPIRED：已过期，这个状态是核销的时候赋值的
          if (status == "USED") {
            return "已核销";
          } else if (status == "EXPIRED") {
            return "已过期";
          } else {
            return "发券成功";
          }
        }
        case "FAILED":
        case "NOTENOUGH":
        case "DAYLIMIT":
        case "NATURELIMIT":
        case "MAXQUOTA":
        case "NOTRUNNING":
        case "EXPIRED":
        case "NOTMONEY":
        case "USERLIMIT":
        case "FREQUENCYLIMIT":
          return "领券失败";
        default:
          return "未知状态";
      }
    },

    handleback() {
      this.$router.back();
    },
    handlekefu() {
      window.location.href =
        "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97832154c3744d9bec1ce2e761e9194029dcd621e4cbceb822e6cd587a8f3ed33e78f6af9dc6c79786d362ef01848a65fd";
    },
    getOrder() {
      Toast.loading({
        message: "处理中...",
        forbidClick: true,
        duration: 0,
      });

      this.post({
        url: "/jfdh/getOrder",
        data: {
          goodOrderId: this.goodOrderId,
        },
        success: (res) => {
          Toast.clear();
          this.status = res.status;
          this.received = res.received;
          this.coupons = res.coupons;
          this.coupon = res.coupon ? res.coupon : null;
        },
        error: (code, err) => {
          Toast.clear();
          Toast.fail(err);
        },
      });
    },
  },
};
</script>
<style scoped>
/* pages/detail/detail.wxss */
.detail-contain {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.detail-success {
  margin-top: 6.03125rem;
  width: 5.875rem;
  height: 6.84375rem;
}

.detail-return {
  position: absolute;
  margin-top: 1.28125rem;
  left: 0.4375rem;
  width: 1.75rem;
  height: 1.75rem;
}

.detail-kefu {
  position: absolute;
  width: 2.625rem;
  height: 2.625rem;
  bottom: 3.125rem;
  right: 1.25rem;
}

.detail-lingqu {
  width: 10.875rem;
  height: 2.4375rem;
  line-height: 2.4375rem;
  background-color: #d04d39;
  color: white;
  font-size: 1.25rem;
  border-radius: 1.21875rem;
  text-align: center;
  margin-top: 1.875rem;
}

.detail-bottom {
  text-align: center;
  position: absolute;
  bottom: 3.125rem;
}

.detail-bottom-1 {
  color: var(--p1color--);
  font-size: 1.09375rem;
}

.detail-bottom-2 {
  margin-top: 0.375rem;
  color: #333;
  font-size: 1.09375rem;
}

.detail-coupons {
  margin-top: 2.5rem;
  /* background-color: red; */
  height: 45vh;
  overflow: scroll;
}

.detail-coupons-item {
  display: flex;
  width: 20.3125rem;
  border-bottom-color: #d8d8d8;
  border-bottom-style: solid;
  border-bottom-width: 0.03125rem;
  padding: 0.6875rem 0rem;
}

.detail-coupons-item-left {
  display: flex;
}

.detail-coupons-item-left img {
  width: 2.8125rem;
  height: 2.8125rem;
  border-radius: 1.40625rem;
  margin-right: 0.4375rem;
}

.detail-coupons-item-right-top {
  width: 17.0625rem;
  display: flex;
  justify-content: space-between;
}

.detail-coupons-item-right-top-left {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.detail-coupons-item-right-top-right {
  font-size: 0.875rem;
  color: #666;
}

.detail-coupons-item-right-bottom {
  margin-top: 0.3125rem;
  font-size: 0.75rem;
  color: #888888;
}

.detail-coupons-item-right-bottom-1 {
  display: inline-block;
}
</style>
