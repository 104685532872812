<template>
  <div class="jtc-container">
    <div class="jtc-scroll">
      <div v-if="jifen" class="jtc-jifen">
        <div class="jtc-jifen-info">
          <span class="jtc-jifen-info-1">{{ phone }}</span>
          <span class="jtc-jifen-info-2" @click="switchclicked">切换</span>
          <span class="jtc-jifen-info-3">移动积分：</span>
          <span class="jtc-jifen-info-4">{{ jifen }}</span>
        </div>
        <div class="jtc-jifen-bg">
          <div class="jtc-jifen-title">本次可兑换</div>
          <div class="jtc-jifen-price">
            {{ dhme }}<span class="jtc-jifen-price-yuan">元</span>
          </div>
          <div class="jtc-jifen-score">消耗移动积分：{{ dhjf }}</div>

          <div class="jtc-coupon-desc2">兑换成功即可使用</div>
        </div>

        <sms @resend="handleResend" @tapok="handletapok"></sms>

        <div class="jtc-jifen-history">
          <div class="jtc-jifen-history-1" @click="handHistory">兑换记录</div>
          <div class="jtc-jifen-history-2" @click="handleguize">活动规则</div>
        </div>
      </div>
      <div v-else>
        <div class="jtc-coupon">
          <div class="jtc-coupon-1">最多可兑</div>
          <div class="jtc-coupon-2">
            <div class="jtc-coupon-2-1">￥</div>
            30
          </div>
          <div class="jtc-coupon-desc">兑换成功即可使用</div>
        </div>

        <div class="jtc-input-content">
          <input
            placeholder-class="jtc-input-placeholder"
            class="jtc-input"
            placeholder="请输入手机号"
            v-model="phone"
          />
        </div>

        <div class="jtc-button" @click="query">立即兑换</div>
        <div class="jtc-history" @click="handHistory">兑换记录</div>
        <div class="jtc-bottom">
          <van-checkbox v-model="agree" checked-color="#58BE6A" shape="square"
            >我已阅读并同意
            <div class="jtc-checkbox-guize" @click.stop="handlexieyi">
              《用户协议》
            </div>
            <div class="jtc-checkbox-guize" @click.stop="handleguize">
              《活动规则》
            </div>
          </van-checkbox>
        </div>
      </div>

      <div class="jtc-toplogo">
        <img
          class="jtc-toplogo-jtc"
          src="http://img.zhsyh.cn/donglian/jtc/IMG56.PNG"
        />
        <img
          class="jtc-toplogo-yidong"
          src="http://img.zhsyh.cn/donglian/jtc/IMG52.png"
        />
      </div>

      <div class="jtc-tishi" v-if="showTishi">
        <div class="jtc-tishi-1">使用提示</div>
        <div class="jtc-tishi-2">
          1、兑换成功后优惠券当天可用，每次使用一张。
        </div>
        <div class="jtc-tishi-3">2、优惠券有效期为7天。</div>
        <div class="jtc-tishi-4">3、如有问题请联系官方客服 400-001-2575</div>
      </div>

      <img
        class="jtc-kefu"
        @click="handlekefu"
        src="http://img.zhsyh.cn/donglian/jtc/kefu.png"
      />
    </div>
    <!-- 协议组件 -->
    <xieyi v-if="isShowxieyi" @bgclicked="xybgclicked"></xieyi>

    <!-- 规则组件 -->
    <guize v-if="isShowguize" @bgclicked="gzbgclicked"></guize>
  </div>
</template>

<script>
import help from "@/utils/Help";
import hhstore from "@/utils/HHStore";

import Vue from "vue";
import guize from "../guize.vue";
import sms from "../sms.vue";
import xieyi from "../xieyi.vue";
import { Checkbox, CheckboxGroup } from "vant";
import { Toast, Dialog } from "vant";
import { isPhoneNumber } from "@/utils/util";
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
export default {
  components: { guize, xieyi, sms },
  props: {
    jifen: {
      type: Number,
      default: null,
    },
    goods: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      couponStyle: "",
      phone: "",
      agree: true,
      isShowxieyi: false,
      isShowguize: false,
      showTishi: true,
    };
  },
  computed: {
    dhme() {
      return this.goods.reduce(
        (acc, cur) => acc + parseInt(cur.coupon_amount) / 100,
        0
      );
    },
    dhjf() {
      return this.goods.reduce((acc, cur) => acc + parseInt(cur.goodsPrice), 0);
    },
  },
  mounted() {
    if (hhstore.get("tmp_phone")) {
      this.phone = hhstore.get("tmp_phone");
    }
  },
  methods: {
    /* 点击兑换记录 */
    handHistory() {
      this.$emit("historyClick");
    },
    handleguize() {
      this.isShowguize = true;
      help.click_log("活动规则按钮");
    },
    handlexieyi() {
      help.click_log("协议按钮");
      this.isShowxieyi = true;
    },
    handlekefu() {
      window.location.href =
        "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97832154c3744d9bec1ce2e761e9194029dcd621e4cbceb822e6cd587a8f3ed33e78f6af9dc6c79786d362ef01848a65fd";
    },

    // 查询用户积分
    async query() {
      if (!this.agree) {
        Toast({
          message: "请先阅读并同意活动规则~",
          icon: null,
        });
        return;
      }

      if (!isPhoneNumber(this.phone)) {
        Toast({
          message: "手机号填写不正确",
          icon: null,
        });
        return;
      }

      hhstore.set("phone", this.phone);
      this.$emit("query", this.phone);
    },
    xybgclicked() {
      this.isShowxieyi = false;
    },
    gzbgclicked() {
      this.isShowguize = false;
    },
    switchclicked() {
      this.phone = "";
      this.$emit("switchclicked");
      
    },
    handleResend() {
      console.info('handleResend')
      this.$emit("resend");
    },
    handletapok(sms) {
      console.info(sms)
      this.$emit("tapok",sms);
    },
  },
};
</script>

<style scoped>
.jtc-container {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  position: relative;
}

.jtc-scroll {
  position: relative;
  /* width: 23.4375rem; */
  width: 100%;
  height: 21.25rem;
  /* height: 51.15625rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("http://img.zhsyh.cn/donglian/jtc/IMG45.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.jtc-toplogo {
  position: absolute;
  top: 2.5rem;
  align-self: flex-start;
  display: flex;
  align-items: center;
}

.jtc-toplogo-jtc {
  margin-left: 1.5625rem;
  width: 5.125rem;
  height: 1.74375rem;
}

.jtc-toplogo-yidong {
  margin-left: 1.5625rem;
  width: 5.71875rem;
  height: 1.8125rem;
}

.jtc-kefu {
  position: absolute;
  width: 4.375rem;
  height: 2.26031rem;
  top: 17.1875rem;
  right: 0rem;
}

.jtc-top-left {
  margin-top: 2.5rem;
  margin-left: 1.28125rem;
  width: 5.0625rem;
  height: 0.9375rem;
}

.jtc-top {
  margin-top: calc(7vh);
  margin-left: 2.5rem;
  width: 18.78125rem;
  height: 8.8125rem;
}

.jtc-coupon {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  margin-top: 13.75rem;
  margin-left: 3.4375rem;
  width: 16.78125rem;
  height: 15.15625rem;
  position: relative;
  background-image: url("http://img.zhsyh.cn/donglian/jtc/hbbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.jtc-coupon-desc {
  margin-top: 35px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  letter-spacing: 2px;
}
.jtc-coupon-desc2 {
  margin-top: 55px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  letter-spacing: 2px;
}

.jtc-coupon-1 {
  margin-top: 0.46875rem;
  color: white;
}

.jtc-coupon-2 {
  margin-top: 1.25rem;
  /* font-weight: 800; */
  font-size: 5.3125rem;
  color: #ec672e;
  font-family: "Bahnschrift";
}

.jtc-coupon-2-1 {
  display: inline-block;
  font-size: 1.5625rem;
  margin-left: -1.25rem;
}

.jtc-input-content {
  margin-top: 0.625rem;
  display: flex;
  justify-content: center;
  width: 23.4375rem;
  align-items: center;
}

.jtc-input {
  border-width: 0px;
  background-color: #ffffff;
  border-radius: 0.3125rem;
  /* position: absolute; */
  /* bottom: 0.21875rem; */
  margin-left: 0.34375rem;
  width: 17.1875rem;
  height: 2.8125rem;
  text-align: center;
  font-size: 0.9375rem;
  color: #666;
  font-weight: normal;
}

.jtc-history {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: #e43f2c;
  text-align: center;
  margin-top: 0.625rem;
  /* letter-spacing: 0.18750rem; */
}

.jtc-history-1 {
}

.jtc-history-2 {
  margin-left: 1.25rem;
}

.jtc-input-placeholder {
  color: #a2a2a2;
}

/* 定义动画 */
@keyframes zoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.jtc-button {
  margin-top: 1.09375rem;
  margin-left: auto;
  margin-right: auto;
  width: 13.8125rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  line-height: 2.5rem;
  color: white;
  text-align: center;
  background-color: #ec6930;
  /* background-image: url('http://img.zhsyh.cn/donglian/btn.png'); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  /* 添加动画 */
  animation: zoom 0.6s ease-in-out alternate infinite;
}

.jtc-checkbox {
  color: #fff;
  font-size: 0.78125rem;
  text-align: center;
}

.jtc-checkbox-guize {
  display: inline;
  font-size: 0.78125rem;
  color: #ffe8bf;
  margin-bottom: 0.125rem;
}

checkbox .wx-checkbox-input {
  width: 1.25rem;
  height: 1.25rem;
  border: 0;
  border-radius: 0.3125rem;
}

.jtc-bottom {
  text-align: center;
  margin-top: 0.625rem;
  /* margin-bottom: 1.87500rem; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.jtc-jifen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jtc-jifen-info {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0 0.625rem 0 0.625rem;
  border-radius: 0.625rem;
  color: #333;
  font-size: 0.78125rem;
  margin-top: 13.4375rem;
}

.jtc-jifen-info-2 {
  color: #d04d39;
  font-size: 0.875rem;
  margin-left: 0.125rem;
}

.jtc-jifen-info-3 {
  margin-left: 0.625rem;
}

.jtc-jifen-info-4 {
}

.jtc-jifen-bg {
  background-image: url("http://img.zhsyh.cn/donglian/jtc/hbbg.png");
  width: 16.78125rem;
  height: 15.15625rem;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 0.75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@font-face {
  font-family: "Bahnschrift";
  src: url("https://img.zhsyh.cn/donglian/bahnschrift.ttf");
}

.jtc-jifen-title {
  margin-top: 0.5625rem;
  font-size: 0.875rem;
  color: white;
}

.jtc-jifen-price {
  display: flex;
  align-items: center;
  color: #ec6930;
  font-family: "Bahnschrift";
  font-size: 4.0625rem;
  margin-top: 0.625rem;
}

.jtc-jifen-price-yuan {
  font-size: 1.5625rem;
  margin-left: 0.15625rem;
}

.jtc-jifen-score {
  color: #2b2b2b;
  font-size: 0.6875rem;
}

.jtc-jifen-btn {
  position: absolute;
  bottom: 0.9375rem;
  left: 2.5rem;
  height: 3.03125rem;

  width: 11.71875rem;
  background-image: url("http://img.zhsyh.cn/donglian/jtc/qrdh.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.jtc-tishi {
  position: absolute;
  bottom: 3.75rem;
  display: flex;
  flex-direction: column;
  /* margin-left: 3.28125rem; */
  margin-top: 3.4375rem;
  font-size: 0.8125rem;
  color: white;
}

.jtc-tishi-1 {
  font-size: 0.9375rem;
  color: #f5ddba;
}

.jtc-tishi-2 {
}

.jtc-tishi-3 {
}

.jtc-tishi-4 {
}

.jtc-jifen-history {
  display: flex;
  margin-top: 0rem;
  font-size: 0.9375rem;
  color: #d04d39;
}

.jtc-jifen-history-1 {
}

.jtc-jifen-history-2 {
  margin-left: 1.5625rem;
}
</style>