import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router' // 引入vue-router
import '../static/res/js/rem'
import 'vant/lib/index.css';
import Home from '@/views/Home'
import Done from '@/views/Done'
import Detail from '@/views/Detail'
import urlParams from './utils/UrlParams'
import config from './utils/config'
import hhstore from './utils/HHStore'
import aes from './utils/Aes'


//---------------------- 获取同盾付的指纹 ---------------------- //
import '../static/res/js/monitor'
(function () {
	window._fmOpt = {
		partner: 'changyou',
		appName: 'changyou_web',
		token: 'changyou' + "-" + new Date().getTime() + "-" + Math.random().toString(16).substr(2),
		fmb: true,
		success: function (data) {
			// 在成功完成采集后，success回调中可以获取到blackbox，在获取到blackbox后传输过程中请进行url编码
			window._fmOpt.blackbox = data
		},
		fpHost: `https://${config.fmOpt.fpHost}`
	};
	var cimg = new Image(1, 1);
	cimg.onload = function () {
		_fmOpt.imgLoaded = true;
	};
	cimg.src = `https://${config.fmOpt.fpHost}/fp/clear.png?partnerCode = changyou & appName=changyou_web & tokenId=` + _fmOpt.token;
	var fm = document.createElement('script'); fm.type = 'text/javascript';
	fm.async = true;
	fm.src = ('https:' == document.location.protocol ? 'https://' : 'http://') +
		'static.tongdun.net/v3/fm.js?ver=0.1&t=' + (new Date().getTime() / 3600000).toFixed(0);
	var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(fm, s);
})();
//---------------------------------------------------------- //

//
var today = new Date(); var year = today.getFullYear(); var month = today.getMonth() + 1; var day = today.getDate(); var ymd = year + "" + month + "" + day; var oHead = document.getElementsByTagName('HEAD').item(0); var oScript = document.createElement("script"); var bsdfp = null; oScript.async = true; oScript.type = "text/javascript"; oScript.src = "https://dfp.cmft.com.cn/dfp/public/downloads/frms-fingerprint.js?custID=dfp&serviceUrl=https://dfp.cmft.com.cn/dfp/public/generate/jsonp&channel=WEB&autoExec=false&ymd=" + ymd; oHead.appendChild(oScript); oScript.onload = function () { bsdfp = new BSDeviceFingerprint(); var op = { extendFields: [] }; bsdfp.getFingerprint(op, function (dfpInfo) { window.localStorage.JK_dfpInfo = JSON.parse(dfpInfo).dfp; }); }
//

Vue.config.productionTip = false
Vue.config.ignoredElements = [
	'wx-open-launch-weapp'
]


Vue.use(VueRouter) //Vue全局使用Router
// Vue.use(Toast);

let ch = urlParams.get('ch') ? urlParams.get('ch') : urlParams.get('card_type')
if (!ch && hhstore.get('card_type')) {
	ch = hhstore.get('card_type')
}

const routes = [{
	path: '/',
	component: Home,
	meta: {
		title: '动联积分',
		// showTab: true
	}
}, {
	path: '/detail',
	component: Detail,
	meta: {
		title: '订单详情',
		// showTab: true
	}
}, {
	path: '/done',
	component: Done,
	meta: {
		title: '兑换成功',
	}
}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach((toRoute, fromRoute, next) => {
	window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : '';

	next();
})

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')





