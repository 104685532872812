<script>
import hhstore from './HHStore'
import store from '../store'
import { postf } from '../store'
var md5 = require('md5')



export default {
	async click_log(name) {
		await postf({
			url: "/user/click_log",
			data: {
				phone: hhstore.get("phone"),
				url: window.location.href,
				title: name,
				card_type: hhstore.get('ch'),
			}
		})
	},
	ckphone(tel) {
		var strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
		if (strTemp.test(tel)) {
			return true;
		} else {
			return false;
		}
	},
	async getLocation(callback = function () { }) {

		try {
			// 获取位置信息
			var position = await this.getPosition();
		} catch (error) {
			callback(false)
			console.log("获取位置信息失败：" + error.message);
			return
		}

		store.commit('SET_LAT', position.coords.latitude)
		store.commit('SET_LNG', position.coords.longitude)


		try {
			var city = await postf({
				url: "film/location",
				data: {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				}
			})
		} catch (error) {
			callback(false)
			console.log(error);
			return
		}

		store.commit('SET_CITY', city)
		callback(true)
	},
	// 地理位置
	getPosition() {
		return new Promise(function (resolve, reject) {
			navigator.geolocation.getCurrentPosition(resolve, reject);
		});
	},

	randomString(length) {
		const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		const charactersLength = characters.length;
		let result = '';
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	},

	// 签名函数
	sign(params, appid, key) {
		// 添加 appid、timestamp 和 oncestr 参数
		params.appid = appid;
		params.timestamp = Math.floor(Date.now() / 1000);
		params.oncestr = this.randomString(20);

		// 删除非基本类型参数
		let data = Object.entries(params)
			.filter(([key, value]) => typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')
			.reduce((obj, [key, value]) => {
				obj[key] = value.toString();
				return obj;
			}, {});

		// 对键名进行排序
		const sortedKeys = Object.keys(data).sort();

		// 构建待签名字符串
		const query = sortedKeys.map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
		const queryString = `${query}&key=${key}`;

		// 计算签名
		const sign = md5(queryString);

		// 在 post 参数中增加签名字段
		params.sign = sign;

		return params;
	}
}
</script>
