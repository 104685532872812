<template>
	<div>
		<van-overlay :show="show" duration="0" @click.stop>

			<div class="loading">
				<!-- <div class="ld ld-spin blockx"></div> -->

				<div class="blockx">
					<img class="ld ld-bounce" src="./assets/3.svg" style="animation-duration:1s;animation-delay:-.00s" />
					<img class="ld ld-bounce" src="./assets/1.svg" style="animation-duration:1s;animation-delay:-.10s" />
					<img class="ld ld-bounce" src="./assets/4.svg" style="animation-duration:1s;animation-delay:-.20s" />
					<!-- <img class="ld ld-bounce" src="@/assets/1.svg" style="animation-duration:1s;animation-delay:-.30s" /> -->
				</div>
			</div>

		</van-overlay>
	</div>
</template>

<script>

import Vue from 'vue';

import {
	Overlay,
} from 'vant';
Vue.use(Overlay);

export default {
	props: {
		show: {
			type: Boolean,
			default: false
		},
	},
	computed: {

	},
	methods: {
		clicked() {
			this.$emit('update:show', false);
		}
	},
}
</script>

<style src="./loading.min.css" scoped/>
<style scoped>
.loading {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: white;
}

.blockx {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	/* background-color: blueviolet; */
}
.blockx img{
	width: 35px;
}
</style>
