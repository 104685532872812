<template>
  <div class="countdown-contain" :style="{ '--p1color--': style.p1color }">
    <!-- 倒计时秒数，通过组件的属性传入 -->
    <div class="countdown-text" v-if="!showResend">
      {{ remainSeconds }}
    </div>
    <!-- 判断是否显示重新获取验证码的按钮 -->
    <div class="countdown-button" v-if="showResend" @click="handleResend">
      重发
    </div>
    
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import config from "../utils/config";

export default {
  props: {
    // 倒计时秒数
    countdown: {
      // type: Number,
      default: 60, // 默认为60秒
    },
  },
  data() {
    return {
      style: config.style(),
      // 倒计时剩余秒数
      remainSeconds: 0,
      // 是否显示重新获取验证码的按钮
      showResend: false,
      // 计时器对象
      timer: null,
    };
  },
  mounted() {
    this.remainSeconds = this.countdown;
    this.startCountdown();
  },
  unmounted() {
    clearInterval(this.timer);
  },
  computed: {},
  methods: {
    // 开始倒计时
    startCountdown: function () {
      this.remainSeconds = this.remainSeconds;

      // 设置计时器
      this.timer = setInterval(() => {
        // 剩余秒数减一
        this.remainSeconds = this.remainSeconds - 1;
        // 如果倒计时结束，清除计时器并显示重新获取验证码的按钮
        if (this.remainSeconds <= 0) {
          clearInterval(this.timer);
          this.showResend = true;
        }
      }, 1000);
    },
    // 重新获取验证码
    handleResend: function () {
      // 重置剩余秒数，并重新开始倒计时
      this.$emit("resend");

      this.remainSeconds = this.countdown;
      this.showResend = this.false;

      this.startCountdown();
    },
  },
};
</script>

<style scoped>
.countdown-contain {
  display: inline-block;
}
.countdown-text {
  color: #fff;
  font-size: .9375rem;
  width: 2.95rem;
  text-align: center;
  line-height: 2.95rem;
  height: 2.95rem;
  border-radius: 5px;
  background-color: var(--p1color--);
}

.countdown-button {
  color: #fff;
  font-size: .9375rem;
  width: 2.95rem;
  height: 2.95rem;
  border-radius: 5px;
  background-color: var(--p1color--);
  text-align: center;
  line-height: 2.95rem;
}
</style>